import moment from "moment";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import ReactDOM from "react-dom";
import {
  borderWidth,
  colors,
  radius,
  shadows,
  spacings,
} from "../../assets/themes";
import { Block, Body14, Caption, Selectable } from "../../components";
import { IS_DEV } from "../../utils";
import { ExperimentContext } from "../contexts";

const Widget = ({ onChange, variantId, variants, name, disabled }) => {
  const View = (
    <Block
      position="fixed"
      bottom={spacings.l}
      right={spacings.l}
      boxShadow={shadows.m}
      border={`solid ${borderWidth.s} ${colors.border}`}
      padding={spacings.m}
      borderRadius={radius.ml}
      backgroundColor={colors.background}
      zIndex="9999999"
    >
      <Body14 align="center" strong>
        A/B testing
      </Body14>
      <Caption align="center">{name}</Caption>
      <Block
        display="flex"
        alignItems="center"
        marginTop={spacings.s}
        justifyContent="center"
      >
        <Block marginX={spacings.s} display="flex" alignItems="center">
          {variants.map(
            (v, i, arr) =>
              v && (
                <Block
                  marginRight={i < arr.length - 1 && spacings.s}
                  key={`variant-widget-selectable-${v}`}
                >
                  <div data-test={false}>
                    <Selectable.Small
                      disabled={disabled}
                      onChange={() => onChange(v)}
                      checked={v === variantId}
                    >
                      {v.toUpperCase()}
                    </Selectable.Small>
                  </div>
                </Block>
              )
          )}
        </Block>
      </Block>
    </Block>
  );
  return ReactDOM.createPortal(View, document.body);
};

const A = "a";
const B = "b";
const C = "c";

const getRandomVariant = (arr) => arr[Math.floor(Math.random() * arr.length)];

const ExperimentProvider = ({
  name,
  children,
  expires = moment().add(30, "days").toDate(),
  variantsAmount = 2,
  canSetCookie = true,
}) => {
  const variants = [A, B, C].slice(0, variantsAmount);
  const [cookies, setCookies] = useCookies([name]);
  const v = canSetCookie ? getRandomVariant(variants) : "";
  const variantId = cookies[name] || v;
  const isA = variantId === "a";
  const isB = variantId === "b";
  const isC = variantId === "c";

  const handleSetCookie = (cookie) => {
    setCookies(name, cookie, { expires });
  };

  useEffect(() => {
    if (!cookies[name] && canSetCookie) {
      handleSetCookie(v);
    }
  }, []);

  return (
    <ExperimentContext.Provider value={{ variantId, name, isA, isB, isC }}>
      {children}
      {IS_DEV && (
        <Widget
          onChange={handleSetCookie}
          variants={variants}
          variantId={variantId}
          name={name}
        />
      )}
    </ExperimentContext.Provider>
  );
};

export default ExperimentProvider;
