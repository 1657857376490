import React from "react";
import { spacings } from "../../../assets/themes";
import { useBreakpoints } from "../../../modules/hooks";
import Block from "../../Block";
import Button from "../../Button";
import Icon from "../../Icon";
import { BUTTON } from "../../Styles/variants";
import { Body16, H1, H2 } from "../../Text";

const Header = ({
  children,
  onBack,
  onClose,
  title,
  subtitle,
  navBarContent,
  className,
}) => {
  const breakpoints = useBreakpoints();

  return (
    <Block display="flex" flexDirection="column" className={className}>
      <Block
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX={spacings.s}
        paddingTop={!onClose && !onBack ? spacings.sm : spacings.s}
      >
        {onBack && (
          <Button.Medium
            kind={BUTTON.KIND.MINIMAL}
            shape={BUTTON.SHAPE.CIRCLE}
            type="button"
            style={{ marginRight: "auto" }}
            onClick={onBack}
          >
            <Icon.Large name="arrow-left" />
          </Button.Medium>
        )}
        {navBarContent && navBarContent()}
        {onClose && (
          <Button.Medium
            kind={BUTTON.KIND.MINIMAL}
            shape={BUTTON.SHAPE.CIRCLE}
            type="button"
            style={{ marginLeft: "auto" }}
            onClick={onClose}
          >
            <Icon.Large name="times" />
          </Button.Medium>
        )}
      </Block>
      {(children || title || subtitle) && (
        <Block
          marginBottom={spacings.sm}
          marginLeft={spacings.m}
          marginRight={spacings.m}
        >
          {children || (
            <>
              {breakpoints.get({ xs: <H2>{title}</H2>, sm: <H1>{title}</H1> })}
              {subtitle && <Body16>{subtitle}</Body16>}
            </>
          )}
        </Block>
      )}
    </Block>
  );
};

export default Header;
