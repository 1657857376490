import { Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, useHistory } from "react-router-dom";
import * as yup from "yup";
import { sizes, spacings } from "../../assets/themes";
import { Alert, Block, Body16, H5, Progress, Spinner } from "../../components";
import { ALERT } from "../../components/Styles/variants";
import {
  useBreakpoints,
  useCategoryForm,
  useGoogleAnalytics,
} from "../../modules/hooks";
import {
  getCategoryFormsNavigateEvent,
  getCategoryFormsOpenEvent,
  getCategoryFormsSubmitEvent,
} from "../../utils/analytics-events";
import CategoryFormContainer from "./CategoryFormContainer";
import CategoryFormHooks from "./CategoryFormHooks";
import CategoryFormsFlow from "./CategoryFormsFlow";
import CategoryFormsRedirection from "./CategoryFormsRedirection";
import WarrantiesCarousel from "./common/WarrantiesCarousel";
import { LOADINGS_PATH, NAVBAR_HEIGHT } from "./constants";

const ProgressBar = ({ activeIndex, routes }) => {
  const View = (
    <Block display={{ xs: "none", md: "block" }}>
      <Progress.Bar
        value={(100 / (routes.length - 1)) * activeIndex}
        css={`
          margin-top: -1px;
          width: 100%;
        `}
      />
    </Block>
  );
  return ReactDOM.createPortal(
    View,
    document.getElementsByTagName("header")[0]
  );
};

const CategoryForms = () => {
  const { sendEvent } = useGoogleAnalytics();
  const flowRef = useRef();
  const formikRef = useRef();
  const history = useHistory();
  const breakpoints = useBreakpoints();
  const {
    pages,
    initialValues,
    isLoaded,
    title,
    id,
    flowControl,
    postForm,
    activeIndex,
    activeSteps,
    setActiveIndex,
    setActiveSteps,
  } = useCategoryForm();
  const currPath = pages[activeIndex]?.path;

  const handlePostJob = () => {
    sendEvent(getCategoryFormsSubmitEvent({ id }));
    // sendEvent(getCategoryFormsSubmitExperimentEvent({ id, name, variantId }));
    history.push(LOADINGS_PATH);
  };

  useEffect(() => {
    // sendEvent(getCategoryFormsOpenExperimentEvent({ id, name, variantId }));
    sendEvent(getCategoryFormsOpenEvent({ id }));
  }, []);

  useEffect(() => {
    if (currPath)
      sendEvent(
        getCategoryFormsNavigateEvent({
          path: currPath.toLowerCase(),
          id,
        })
      );
  }, [activeSteps]);

  const handleSubmit = (values) => {
    handlePostJob(values);
  };

  const handleStepChange = (step, history) => {
    if (step < 0) {
      history.replace({
        pathname: pages[activeIndex].path,
        state: history.location.state,
      });
    } else {
      setActiveIndex(step);
      if (!activeSteps.includes(step)) setActiveSteps((n) => [...n, step]);
    }
    if (flowControl.getNextIsLoading()) {
      flowControl.setNextIsLoading(false);
    }
  };

  return (
    <Block
      marginTop={{ xs: spacings.ml, md: `calc(${spacings.l} + ${spacings.m})` }}
      marginBottom={NAVBAR_HEIGHT}
      paddingBottom={{ xs: spacings.s, md: spacings.l }}
    >
      {isLoaded ? (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={() => {
            // prevent crash if page dont exist (work for auto redirection if index is wrong)
            if (pages[activeIndex]) {
              return yup.lazy(() => pages[activeIndex].validationSchema);
            }
            return yup.lazy(() => pages[0].validationSchema);
          }}
        >
          {({ status }) => (
            <Form id="flow-router">
              <CategoryFormHooks activeSteps={activeSteps} />
              <Route exact path="/">
                <HashRouter>
                  <WarrantiesCarousel />
                  <ProgressBar activeIndex={activeIndex} routes={pages} />
                  <Block marginBottom={`calc(${spacings.l} + ${spacings.m})`}>
                    <CategoryFormContainer
                      css={`
                        padding-bottom: calc(
                          ${sizes.size36} + (${spacings.m} * 2)
                        );
                      `}
                    >
                      {status?.warning && (
                        <Block marginBottom={spacings.m}>
                          <Alert.Low kind={ALERT.KIND.WARNING}>
                            {status?.warning}
                          </Alert.Low>
                        </Block>
                      )}
                      <Block marginBottom={spacings.s}>
                        {breakpoints.get({
                          xs: <Body16 strong>{title}</Body16>,
                          sm: <H5>{title}</H5>,
                        })}
                      </Block>
                      <CategoryFormsFlow
                        ref={flowRef}
                        pages={pages}
                        onChange={handleStepChange}
                        activeIndex={activeIndex}
                        isLoading={postForm.isLoading || postForm.isSuccess}
                      />
                    </CategoryFormContainer>
                  </Block>
                </HashRouter>
              </Route>
              <Route path={LOADINGS_PATH}>
                <CategoryFormsRedirection />
              </Route>
            </Form>
          )}
        </Formik>
      ) : (
        <Block marginY={spacings.xxl} display="flex" justifyContent="center">
          <Spinner.Medium />
        </Block>
      )}
    </Block>
  );
};

export default CategoryForms;
