import { motion, useAnimation, useMotionValue } from "framer-motion";
import React, { useRef, useState, useImperativeHandle, useEffect } from "react";
import styled, { css } from "styled-components";
import { colors, shadows, sizes } from "../assets/themes";
import { useWindowSize } from "../modules/hooks";
import { BREAKPOINTS } from "../utils";
import Button from "./Button";
import { Col, Row } from "./Grid";
import Icon from "./Icon";
import { getCssProperty } from "./Styles/Helper";
import { BUTTON } from "./Styles/variants";

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledChildWrapper = styled.div`
  overflow: hidden;
`;

const StyledNavigation = styled.div`
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  button {
    box-shadow: ${shadows.s};
  }
  ${({ position, paddingX = "0px" }) =>
    css`
      ${position}: calc(((${sizes.size36} / 2.5) * -1) + ${paddingX});
    `};
`;

const withBreakpoints = (val, width) => {
  if (typeof val === "object") {
    if (val.xl && width >= BREAKPOINTS.xl) return val.xl;
    if (val.lg && width >= BREAKPOINTS.lg) return val.lg;
    if (val.md && width >= BREAKPOINTS.md) return val.md;
    if (val.sm && width >= BREAKPOINTS.sm) return val.sm;
    return val.xs;
  }
  return val;
};

const Carousel = React.forwardRef(
  (
    {
      children,
      slideToShow: _slideToShow = 1,
      slideToScroll: _slideToScroll = 1,
      gutter,
      draggable = true,
      hideNavigation,
      paddingX: _paddingX,
      showDisabledNavigation,
      onChange,
      ...rest
    },
    ref
  ) => {
    const { width } = useWindowSize();
    const [activePage, setActivePage] = useState(1);
    const pageRef = useRef();
    const slideToShow = withBreakpoints(_slideToShow, width);
    const paddingX = withBreakpoints(_paddingX, width);
    // TODO - slideToScroll
    const slideToScroll = 1;
    const validChildren = React.Children.toArray(children).filter(
      (child) => child !== null
    );
    const childCount = validChildren.length;
    const activeIndex = activePage * slideToShow;
    const transition = { transition: { ease: "easeInOut" } };
    const x = useMotionValue(0);
    const controls = useAnimation();

    const canNext = activePage + Math.floor(slideToShow) <= childCount;
    const canPrev = activePage > 1;

    const getNewPosition = (active) => {
      if (active === 1) {
        return 0;
      }
      const gutterSize = gutter
        ? Number(getCssProperty(gutter).replace("px", ""))
        : 0;

      const pageSize =
        pageRef?.current?.getBoundingClientRect()?.width + gutterSize;
      return -(pageSize * (slideToScroll / slideToShow) * (active - 1));
    };

    const handleNext = () => {
      if (canNext) {
        let newIndex = activePage + 1;
        if (slideToShow % 1 !== 0 && newIndex === childCount) {
          newIndex = activePage + ((slideToShow % 1) - 1) * -1;
        } else {
          newIndex = Math.ceil(newIndex);
        }
        setActivePage(newIndex);
        controls.start({ x: getNewPosition(newIndex), transition });
      }
      if (onChange) onChange(activeIndex);
    };

    const handlePrev = () => {
      if (canPrev) {
        const newIndex = Math.ceil(activePage - 1);
        setActivePage(newIndex);
        controls.start({ x: getNewPosition(newIndex), transition });
      }
      if (onChange) onChange(activeIndex);
    };

    const handleGoTo = (v) => {
      const newIndex = Math.ceil(v);
      setActivePage(newIndex);
      controls.start({ x: getNewPosition(newIndex), transition });
      if (onChange) onChange(activeIndex);
    };

    useEffect(() => {
      controls.start({ x: getNewPosition(1), transition });
    }, [width]);

    useImperativeHandle(
      ref,
      () => ({
        goNext: handleNext,
        goPrev: handlePrev,
        goTo: handleGoTo,
        canNext,
        canPrev,
      }),
      [activeIndex]
    );

    const handleDragEnd = (_, info) => {
      const swipeThreshold = 50;
      if (info.offset.x > swipeThreshold && canPrev) {
        handlePrev();
      } else if (info.offset.x < -swipeThreshold && canNext) {
        handleNext();
      } else {
        controls.start({ x: getNewPosition(activePage), transition });
      }
    };

    return (
      <StyledWrapper {...rest}>
        <StyledChildWrapper>
          <motion.div
            ref={pageRef}
            animate={controls}
            style={{
              x,
              marginLeft: paddingX,
              marginRight: paddingX,
            }}
            drag={draggable ? "x" : null}
            onDragEnd={handleDragEnd}
          >
            <Row style={{ flexWrap: "nowrap" }} gutter={gutter}>
              {React.Children.map(children, (child, i) =>
                child ? (
                  <Col key={`slide-${i}`} size={12 / slideToShow}>
                    {React.cloneElement(child)}
                  </Col>
                ) : null
              )}
            </Row>
          </motion.div>
        </StyledChildWrapper>
        {!hideNavigation && (
          <>
            {(canPrev || showDisabledNavigation) && (
              <StyledNavigation position="left" paddingX={paddingX}>
                <Button.Small
                  shape={BUTTON.SHAPE.CIRCLE}
                  kind={BUTTON.KIND.SECONDARY}
                  disabled={!canPrev}
                  onClick={handlePrev}
                >
                  <Icon.Medium name="arrow-left" color={colors.body} />
                </Button.Small>
              </StyledNavigation>
            )}
            {(canNext || showDisabledNavigation) && (
              <StyledNavigation position="right" paddingX={paddingX}>
                <Button.Small
                  shape={BUTTON.SHAPE.CIRCLE}
                  kind={BUTTON.KIND.SECONDARY}
                  disabled={!canNext}
                  onClick={handleNext}
                >
                  <Icon.Medium name="arrow-right" color={colors.body} />
                </Button.Small>
              </StyledNavigation>
            )}
          </>
        )}
      </StyledWrapper>
    );
  }
);

export default Carousel;
