import { colors, sizes, spacings } from "@/assets/themes";
import { Block, Body14, Body16, Link, List } from "@/components";
import { CardBase } from "@/components/Styles/Base";
import polyglot from "@/utils/polyglot";
import { DASHBOARD_PATH } from "../config/routes";
import UserPreview from "@/pages-admin/UserPreview";
import { JOB } from "@/pages-admin/UserPreview/config";

const MAX_JOBS = 3;

const HomeActiveJobs = ({ active_jobs }) => (
  <>
    <List.Header>{polyglot.t("home.active_jobs")}</List.Header>
    <CardBase
      light
      flat
      css={`
        overflow: hidden;
        margin: ${spacings.s} 0;
      `}
    >
      <List.Group>
        {active_jobs.slice(0, MAX_JOBS).map((job) => (
          <UserPreview id={job.id} kind={JOB}>
            <List.Item
              chevron
              withGutters
              href={job.url}
              LeftComponent={() => (
                <img
                  alt={job.title}
                  src={job.cover}
                  css={`
                    width: ${sizes.size54};
                    height: ${sizes.size54};
                    object-fit: cover;
                    object-position: bottom right;
                    border-radius: ${spacings.sm};
                  `}
                />
              )}
            >
              <Body16 strong>{job.title}</Body16>
              <Body14 color={colors.muted}>{job.datetime_formatted}</Body14>
            </List.Item>
          </UserPreview>
        ))}
      </List.Group>
      {active_jobs.length > MAX_JOBS && (
        <Block
          marginBottom={spacings.m}
          marginX={spacings.m}
          display="inline-flex"
        >
          <Link to={DASHBOARD_PATH}>{polyglot.t("common.see_all")}</Link>
        </Block>
      )}
    </CardBase>
  </>
);

export default HomeActiveJobs;
