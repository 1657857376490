import { useContext, useState } from "react";
import ReferralArtwork from "../../assets/images/referral/referral-artwork.svg";
import { radius, sizes, spacings } from "../../assets/themes";
import {
  Block,
  Body16,
  Body18,
  Button,
  Col,
  Divider,
  H4,
  Input,
  Link,
  List,
  NoResult,
  Row,
} from "../../components";
import PageTitle from "./PageTitle";
import { CardBase } from "../../components/Styles/Base";
import { BUTTON, DIVIDER, INPUT } from "../../components/Styles/variants";
import { DashboardContext } from "../../modules/contexts";
import { useGoogleAnalytics, useToast } from "../../modules/hooks";
import {
  useGetReferral,
  usePutShareReferral,
} from "../../modules/routes/dashboard-routes";
import { CUSTOMER } from "../../pages-admin/UserPreview/config";
import UserPreview from "../../pages-admin/UserPreview/UserPreview";
import {
  getReferralCopyLinkEvent,
  getReferralShareClickEvent,
} from "../../utils/analytics-events";
import polyglot from "../../utils/polyglot";
import ReferredUserItem from "./referral/ReferredUserItem";
import ShareModal from "./referral/ShareModal";
import ReferralSkeleton from "./skeletons/ReferralSkeleton";

const Referral = () => {
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
  const { data, isLoading } = useGetReferral();
  const { public_pages } = useContext(DashboardContext);
  const putShareReferral = usePutShareReferral();
  const { sendEvent } = useGoogleAnalytics();
  useState(false);
  const toast = useToast();

  const handleCloseShareModal = () => {
    setShareModalIsOpen(false);
  };

  const handleOpenShareModal = () => {
    setShareModalIsOpen(true);
    putShareReferral.mutate();
    sendEvent(getReferralShareClickEvent());
  };

  const handleCopy = () => {
    sendEvent(getReferralCopyLinkEvent());
    navigator.clipboard.writeText(data.referral_link);
    toast.remove();
    toast.success(polyglot.t("referral.link_copy_success"));
  };

  return (
    <>
      {!isLoading && data ? (
        <Row gutter={[spacings.l]}>
          <Col size={{ xs: 12, lg: 8 }}>
            <img
              src={ReferralArtwork}
              width="100%"
              height="auto"
              alt="artwork"
              css={`
                border-radius: ${radius.l};
              `}
            />
            <Block marginTop={spacings.ml}>
              <PageTitle>{polyglot.t("routes.referral_description")}</PageTitle>
            </Block>
            <Block marginTop={spacings.xs}>
              <Body16>
                {polyglot.t("referral.invite_your_friends_description", {
                  amount: polyglot.toSmartCurrency(3, { noDecimal: true }),
                })}
              </Body16>
              <Link href={public_pages.referral}>
                {polyglot.t("common.find_out_more")}
              </Link>
            </Block>
            <Block marginTop={spacings.ml}>
              <CardBase>
                <Block display="flex" padding={spacings.s} alignItems="center">
                  <Block flex="1" padding={spacings.s}>
                    <H4 align="center">
                      {polyglot.toSmartCurrency(data.earned_amount)}
                    </H4>
                    <Body18 align="center">
                      {polyglot.t("referral.already_earned")}
                    </Body18>
                  </Block>
                  <Block marginY={spacings.s}>
                    <Divider.Cell
                      orientation={DIVIDER.ORIENTATION.VERTICAL}
                      css={`
                        height: ${sizes.size48};
                      `}
                    />
                  </Block>
                  <Block flex="1" padding={spacings.s} alignItems="center">
                    <H4 align="center">{data.referees?.length}</H4>
                    <Body18 align="center">
                      {polyglot.t("common.referrals")}
                    </Body18>
                  </Block>
                </Block>
              </CardBase>
            </Block>
            <List.Header>{polyglot.t("referral.send_link")}</List.Header>
            <Body16>{polyglot.t("referral.send_link_description")}</Body16>
            <Block marginTop={spacings.sm}>
              <Input
                value={data.referral_link}
                readOnly
                size={INPUT.SIZE.LARGE}
                RightComponent={() => (
                  <Block display="flex">
                    <Block marginRight={spacings.s}>
                      <Button.Small
                        onClick={handleCopy}
                        kind={BUTTON.KIND.MINIMAL}
                        css={`
                          background-color: transparent;
                        `}
                      >
                        {polyglot.t("common.copy")}
                      </Button.Small>
                    </Block>
                    <Button.Small onClick={handleOpenShareModal}>
                      {polyglot.t("common.share")}
                    </Button.Small>
                  </Block>
                )}
              />
            </Block>
            <List.Header>{polyglot.t("common.referrals")}</List.Header>
            {data.referees?.length > 0 ? (
              data.referees.map((referee) => (
                <UserPreview id={referee.id} kind={CUSTOMER}>
                  <ReferredUserItem
                    key={`referral-referee-${referee.id}`}
                    first_name={referee.first_name}
                    last_name={referee.last_name}
                    earned_amount={referee.earned_amount}
                    referral_rewards={referee.referral_rewards}
                  />
                </UserPreview>
              ))
            ) : (
              <NoResult
                css={`
                  margin-top: 0;
                `}
                title={polyglot.t("referral.no_referee")}
                subtitle={polyglot.t("referral.no_referee_description")}
              />
            )}
            <ShareModal
              isOpen={shareModalIsOpen}
              onClose={handleCloseShareModal}
              referralUrl={data.referral_link}
            />
          </Col>
          <Col size={{ xs: 12, lg: 4 }}>
            <CardBase
              css={`
                padding-bottom: ${spacings.xs};
                position: sticky;
                top: calc(var(--nav-height) + ${spacings.m});
              `}
            >
              <List.Header
                withGutters
                RightComponent={() => (
                  <Link href={public_pages.faq} target="_blank">
                    {polyglot.t("common.see_more")}
                  </Link>
                )}
              >
                {polyglot.t("common.need_help")}
              </List.Header>
              <List.Group>
                {data.zendesk_articles.map((article, i) => (
                  <List.Item
                    key={`referral-zendesk-article-${i}`}
                    chevron
                    withGutters
                    href={article.url}
                    target="_blank"
                  >
                    {article.title}
                  </List.Item>
                ))}
              </List.Group>
            </CardBase>
          </Col>
        </Row>
      ) : (
        <ReferralSkeleton />
      )}
    </>
  );
};
export default Referral;
