import { colors, sizes, spacings } from "../../assets/themes";
import { Avatar, Block, Divider, H3, H4 } from "../../components";
import { useBreakpoints } from "../../modules/hooks";
import polyglot from "../../utils/polyglot";

const CategoryUserHeader = ({ first_name, avatar }) => {
  const breakpoints = useBreakpoints();

  return (
    <div>
      <Block
        display="flex"
        alignItems="center"
        marginBottom={spacings.ml}
        marginTop={{ xs: spacings.ml, sm: 0 }}
      >
        <Block flex="1" marginRight={spacings.s}>
          {breakpoints.get({
            xs: (
              <H4>
                {polyglot.t("user.show.ask_service_jobber", {
                  first_name,
                })}
              </H4>
            ),
            sm: (
              <H3>
                {polyglot.t("user.show.ask_service_jobber", {
                  first_name,
                })}
              </H3>
            ),
          })}
        </Block>
        <Avatar src={avatar} size={sizes.size56} name={first_name} />
      </Block>
    </div>
  );
};

export default CategoryUserHeader;
