import { useMutation } from "@tanstack/react-query";
import { getCategoryFormQueryId } from "../../pages/categoryForms/utils";
import { api } from "../../utils";
import polyglot from "../../utils/polyglot";
import { useFetch, useLogin, useToast } from "../hooks";
import { useSession, useSignUp } from "./sign-routes";

export const useGetFormData = ({
  id,
  previous_job_id,
  category_origin_id,
  version,
}) =>
  useFetch(window.location.pathname, {
    params: { category: id, previous_job_id, category_origin_id, version },
    queryKey: getCategoryFormQueryId({ id }),
    refetchOnWindowFocus: false,
  });

export const useCheckJobId = () =>
  useMutation(({ id }) => api.get("/jobs/check_id", { params: { id } }));

export const useAddCustomer = () =>
  useMutation(({ first_name, last_name, date_of_birth, email, phone }) =>
    api.post("/concierges/create_customer", {
      first_name,
      last_name,
      date_of_birth,
      email,
      phone,
    })
  );

const usePostJob = () => {
  const toast = useToast();
  return useMutation((values) => api.post("/jobs", values), {
    onError: () => {
      toast.danger(polyglot.t("common.problem_has_occurred"));
    },
  });
};

export const useCheckEmail = () =>
  useMutation((params) => api.get("/users/check_email", { params }));

export const usePostForm = ({ checkEmail }) => {
  const toast = useToast();
  const signUp = useSignUp();
  const session = useSession();
  const { isLogged } = useLogin();
  const postJob = usePostJob();

  const getCustomMutation = (values) => {
    if (!isLogged) {
      if (checkEmail.data?.data?.data?.redirection === "login") {
        session.mutate(
          { user: values.user, alert_setting: values.alert_setting },
          {
            onSuccess: () => postJob.mutate(values),
            // onError: handled in <CurrentPassword/> -> for missmatch domain modal handle
          }
        );
      } else
        signUp.mutate(
          { user: values.user, alert_setting: values.alert_setting },
          {
            onSuccess: () => postJob.mutate(values),
            onError: () =>
              toast.danger(polyglot.t("common.problem_has_occurred")),
          }
        );
    } else {
      postJob.mutate(values);
    }
  };
  return {
    ...postJob,
    queries: { session, signUp },
    mutate: getCustomMutation,
    resetQueries: () => {
      postJob.reset();
      session.reset();
      signUp.reset();
    },
    isLoading: signUp.isLoading || session.isLoading || postJob.isLoading,
    isError: signUp.isError || session.isError || postJob.isError,
  };
};

export const useGetPreshowOffers = (options) =>
  useFetch(window.location.pathname, options);

export const useFetchDistance = (
  { destination_address_location, address_location },
  options
) =>
  useFetch(
    `/jobs/distance_and_duration?latitude=${address_location?.latitude}&longitude=${address_location?.longitude}&destination_latitude=${destination_address_location?.latitude}&destination_longitude=${destination_address_location?.longitude}`,
    {
      ...options,
    }
  );
