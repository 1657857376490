import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import polyglot from "@/utils/polyglot";
import { colors, shadows, spacings } from "../../assets/themes";
import { Block, Body18, Col, Input, Modal, Row } from "../../components";
import { useBreakpoints, useIntersectionObserver } from "../../modules/hooks";
import { api, getCategoryFormUrlById, uuid } from "../../utils";
import { MAX_TITLE_LENGTH } from "../categoryForms/constants";
import CategoriesNavigationSkeleton from "./CategoriesNavigationSkeleton";
import CategoryUserHeader from "./CategoryUserHeader";
import CustomAskItem from "./CustomAskItem";
import MainCategoryItem from "./MainCategoryItem";
import SearchBreadcrumb from "./SearchBreadcrumb";
import SearchItem from "./SearchItem";
import SelectCategoryModal from "./SelectCategoryModal";
import { launchSearch } from "./searchAlgo";
import { getCategoriesPathById, getCategoryDataById } from "./utils";

let to;

const MIN_SEARCH_LENGTH = 2;

const Show = ({ url_params, user, goTo, onClose }) => {
  const anchorRef = useRef();
  const [navigationData, setNavigationData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [inputIsLoading, setInputIsLoading] = useState(false);
  const [sessionId] = useState(uuid());
  const [selectCategoryModalStatus, setSelectCategoryModalStatus] = useState({
    isOpen: false,
  });
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const inputRef = useRef();
  const history = useHistory();
  const breakpoints = useBreakpoints();
  const searchMode = inputRef.current?.value?.length > MIN_SEARCH_LENGTH;
  const searchParams = new URLSearchParams(history.location.search);
  const currId = Number(searchParams.get("search"));
  const [isStickyFloating, setisStickyFloating] = useState(false);

  useIntersectionObserver({
    target: anchorRef,
    onObserver: (entry) => {
      setisStickyFloating(!entry.isIntersecting);
    },
  });

  useEffect(() => {
    if (currId && inputRef.current) {
      setSearchResults([]);
      inputRef.current.value = "";
    }
    setNavigationData(getCategoryDataById(currId));
  }, [history.location.search]);

  useEffect(() => {
    if (searchMode) goTo("");
  }, [searchMode]);

  const sendSearchTerm = (data) => {
    if (data && data.term.length <= 100) {
      api.post("/searchbar", data);
    }
  };

  const handleChange = (e) => {
    e.persist();
    clearTimeout(to);
    if (e.target?.value?.length > MIN_SEARCH_LENGTH) {
      setInputIsLoading(true);
      to = setTimeout(() => {
        setInputIsLoading(false);
        sendSearchTerm({ term: e.target.value, session: sessionId });
        setSearchResults(launchSearch(e.target.value));
      }, 500);
    } else {
      setSearchResults([]);
      setInputIsLoading(false);
    }
  };

  const getSearchResults = useCallback(
    () => (
      <>
        {searchResults.map(({ item: _elem }) => (
          <SearchItem
            key={`${_elem.id}search`}
            elem={_elem}
            onClick={() => goTo(_elem.id)}
            url_params={url_params}
          />
        ))}
        <Modal.Item.Wrapper>
          <CustomAskItem
            onClick={() =>
              setSelectCategoryModalStatus({
                isOpen: true,
                urlParams: { title: inputRef.current.value },
              })
            }
          />
        </Modal.Item.Wrapper>
      </>
    ),
    [searchResults]
  );

  const getNavigationItems = useCallback(
    () =>
      !currId ? (
        <Block
          marginTop={spacings.s}
          marginBottom={{ xs: 0, sm: `calc(${spacings.m} * -1)` }}
        >
          <Modal.Item.Wrapper>
            <Row gutter={spacings.m}>
              <Col size={{ xs: 12, sm: 6 }}>
                {navigationData.slice(0, 5).map((elem) => (
                  <MainCategoryItem
                    elem={elem}
                    onClick={() => goTo(elem.id)}
                    key={`transition-main-${elem.id}`}
                  />
                ))}
              </Col>
              <Col size={{ xs: 12, sm: 6 }}>
                {navigationData.slice(5).map((elem) => (
                  <MainCategoryItem
                    elem={elem}
                    onClick={() => goTo(elem.id)}
                    key={`transition-main-${elem.id}`}
                  />
                ))}
              </Col>
            </Row>
          </Modal.Item.Wrapper>
        </Block>
      ) : (
        navigationData.map((elem, i) => (
          <div key={`${elem.id}-${i}-navigation-item-transition`}>
            {!elem.children && elem.isCustom && currId ? (
              <Modal.Item.Wrapper>
                <CustomAskItem href={getCategoryFormUrlById(elem.id)} />
              </Modal.Item.Wrapper>
            ) : (
              <SearchItem
                chevron
                elem={elem}
                url_params={url_params}
                onClick={() => goTo(elem.id)}
              />
            )}
          </div>
        ))
      ),
    [navigationData, currId]
  );

  const handleSelectCategoryClose = () => {
    setSelectCategoryModalStatus({ isOpen: false });
  };

  const handleBack = () => {
    const paths = getCategoriesPathById(currId);
    goTo(paths[paths.length - 2]);
  };

  return (
    <>
      <div ref={anchorRef} />
      <Block
        paddingBottom={{ xs: spacings.s, sm: 0 }}
        boxShadow={
          isStickyFloating ? shadows.s : "0px 0px 0px rgba(0, 0, 0, 0)"
        }
        transition="box-shadow 0.2s ease-out"
        background={colors.background}
        zIndex="100"
        position="sticky"
        top="0"
      >
        <Modal.Item.Header
          onBack={currId ? handleBack : null}
          onClose={onClose}
          navBarContent={() => (
            <>
              <Block
                display={{ xs: "block", sm: "none" }}
                position="absolute"
                left="0"
                right="0"
                textAlign="center"
                pointerEvents="none"
              >
                <Body18 strong align="center">
                  {currId
                    ? polyglot.t(`categories.${currId}.name`)
                    : polyglot.t("jobs.ask_service")}
                </Body18>
              </Block>
            </>
          )}
        >
          {breakpoints.get({
            xs: null,
            sm: (
              <Modal.Item.Title>
                {currId
                  ? polyglot.t(`categories.${currId}.name`)
                  : polyglot.t("jobs.ask_service")}
              </Modal.Item.Title>
            ),
          })}
        </Modal.Item.Header>
        {/* <Divider.Cell
        css={`
          margin-top: ${spacings.s};
        `}
      /> */}

        <Modal.Item.Wrapper>
          {user?.first_name && user?.avatar && (
            <CategoryUserHeader
              first_name={user.first_name}
              avatar={user.avatar}
            />
          )}
        </Modal.Item.Wrapper>
        <Block marginTop={{ xs: spacings.xs, sm: null }}>
          <Modal.Item.Wrapper>
            <Input
              maxLength={MAX_TITLE_LENGTH}
              isLoading={inputIsLoading}
              onChange={handleChange}
              ref={inputRef}
              onFocus={() => setInputIsFocused(true)}
              onBlur={() => setInputIsFocused(false)}
              placeholder={
                inputIsFocused
                  ? polyglot.t("ask_service.search_placeholder")
                  : polyglot.t("ask_service.search_job")
              }
              type="search"
            />
          </Modal.Item.Wrapper>
        </Block>
        <Modal.Item.Wrapper>
          <Block
            display={{ xs: "none", sm: "block" }}
            marginTop={spacings.sm}
            marginBottom={spacings.xs}
          >
            {!searchMode && <SearchBreadcrumb onClick={goTo} currId={currId} />}
          </Block>
        </Modal.Item.Wrapper>
      </Block>
      <Block display="flex" flexDirection="column" overflow="hidden">
        {inputIsLoading && searchResults.length === 0 ? (
          <Modal.Item.Wrapper>
            <CategoriesNavigationSkeleton />
          </Modal.Item.Wrapper>
        ) : (
          <AnimatePresence history={history} initial={false} exitBeforeEnter>
            <motion.div
              initial={{
                x: 50,
                opacity: 0,
              }}
              transition={{ duration: 0.2, ease: [1, 0, 0, 1] }}
              animate={{
                x: 0,
                opacity: 1,
              }}
              key={`animate-${currId}`}
            >
              {searchMode ? getSearchResults() : getNavigationItems()}
            </motion.div>
          </AnimatePresence>
        )}
      </Block>
      <SelectCategoryModal
        isOpen={selectCategoryModalStatus.isOpen}
        onClose={handleSelectCategoryClose}
        urlParams={{ ...selectCategoryModalStatus.urlParams, ...url_params }}
      />
    </>
  );
};

export default Show;
