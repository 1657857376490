import React, { useState } from "react";
import Select from "../../../components/Select";
import { useDebounce } from "../../../modules/hooks";
import { useGetZipCode } from "../../../modules/routes/location-routes";
import polyglot from "../../../utils/polyglot";

const getOptions = (data) => {
  if (data) {
    return data.map(({ description, id, coordinates }) => ({
      label: description,
      value: id,
      location: coordinates,
    }));
  }
  return null;
};

const SelectZipCode = ({
  name,
  onChange,
  error,
  disabled,
  value: _value = {},
  placeholder = polyglot.t("common.placeholder.zip_code"),
  ...rest
}) => {
  const [value, setValue] = useState(_value);
  const [term, setTerm] = useState("");
  const debouncedTerm = useDebounce(term);

  const { data, isFetching } = useGetZipCode(
    { term: debouncedTerm },
    {
      enabled: debouncedTerm === term && !!debouncedTerm,
    }
  );

  const handleChange = (option) => {
    setValue(option);
    if (onChange) onChange(option);
  };

  const isLoading = isFetching || debouncedTerm !== term;

  return (
    <Select
      name={name}
      disabled={disabled}
      error={error}
      options={getOptions(data)}
      isLoading={isLoading}
      onChange={handleChange}
      clearable
      value={value}
      placeholder={placeholder}
      onInputChange={(e) => setTerm(e.target.value)}
      {...rest}
    />
  );
};

export default SelectZipCode;
