import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ACCOUNT_TAXES_PATH,
  ACCOUNT_WALLET_PATH,
  REFERRAL_PATH,
} from "../../pages/dashboard/config/routes";
import { formatDate } from "../../utils";
import { api } from "../../utils/axios";
import polyglot from "../../utils/polyglot";
import { ADD_BADGE, REMOVE_BADGE } from "../../utils/types";
import { useFetch, useInfiniteFetch, useToast } from "../hooks";
import { getCategoryDataById } from "@/pages/categoriesNavigationModal/utils";
/* eslint no-param-reassign: ["error", { "props": false }] */

export const getCancelReviewUrl = ({ id }) => `/offers/${id}/cancel_review`;

const useUpdateByKey = (key) => {
  const queryClient = useQueryClient();
  return (data) => queryClient.setQueryData([key], data.data);
};

export const useRemoveBadges = () => {
  const dispatch = useDispatch();
  return (target, n) =>
    dispatch({ type: REMOVE_BADGE, payload: { target, n } });
};

const useForceRefresh = (queryKey, key) => {
  const queryClient = useQueryClient();
  useEffect(() => {
    const isCache = queryClient.getQueryData([queryKey]);
    const hasData = queryClient.getQueryData(["/dashboard"]);
    if (isCache && hasData && hasData[key] > 0) {
      queryClient.removeQueries([queryKey]);
    }
  }, []);
};

export const usePutRefund = ({ onClose }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(() => api.put("/account/wallet/ask_for_refund"), {
    onSuccess: () => {
      queryClient.setQueryData([ACCOUNT_WALLET_PATH], (old) => {
        const n = old;
        if (n.pages[n.pages.length - 1]?.data) {
          n.pages[n.pages.length - 1].data.refund_in_progress = true;
        }
        return n;
      });
      toast.success(polyglot.t("wallet.refund_success"));
      onClose();
    },
    onError: () => {
      toast.danger(polyglot.t("common.problem_has_occurred"));
      onClose();
    },
  });
};

export const usePostAvatar = () => {
  const [progress, setProgress] = useState();
  const toast = useToast();
  const config = {
    onUploadProgress({ loaded, total }) {
      const percentCompleted = Math.round((loaded * 100) / total);
      setProgress(percentCompleted);
    },
    timeout: 0,
  };
  const postAvatar = useMutation(
    ({ values }) =>
      api.put("/account/informations/update_avatar", values, config),
    {
      onSuccess: () => {
        window.location.reload();
      },
      onError: () => {
        toast.danger(polyglot.t("common.problem_has_occurred"));
      },
    }
  );
  return { ...postAvatar, progress };
};

export const useGetAccount = () => useFetch("/account");

export const useLogout = () =>
  useMutation(() => api.delete("/logout"), {
    onSuccess: () => {
      window.location.href = "/";
    },
  });

export const useGetDashboard = () => {
  const dispatch = useDispatch();
  const badges = useSelector((state) => state.badges);
  const dashboardQuery = useFetch("/dashboard", {
    refetchInterval: 60000,
    onSuccess: (data) => {
      dispatch({ type: ADD_BADGE, payload: data });
    },
    shadow: true,
  });
  return { isLoading: dashboardQuery.isLoading, data: badges };
};

export const useGetTransactionDetails = (path, opts) => useFetch(path, opts);

export const usePutTicketMessage = () => {
  const { id } = useParams();
  const updateByKey = useUpdateByKey(`/tickets/${id}`);
  return useMutation(
    (values) => api.post(`/tickets/${id}/create_message`, values),
    {
      onSuccess: ({ data }) => updateByKey(data),
    }
  );
};

const useUpdateUnread = () => {
  const queryClient = useQueryClient();
  const removeBadge = useRemoveBadges();
  const { id } = useParams();
  return (key, parents, target, badge) => {
    queryClient.setQueryData([key], (oldData) => {
      const n = oldData;
      if (n?.pages[0]) {
        n.pages.forEach((page) => {
          page.data[parents[0]]?.forEach((child) => {
            if (Number(child.id) === Number(id) && child[target] > 0) {
              if (badge) removeBadge(badge, child[target]);
              child[target] = 0;
            }
          });
          if (parents[1])
            page.data[parents[1]]?.forEach((child) => {
              if (Number(child.id) === Number(id) && child[target] > 0) {
                if (badge) removeBadge(badge, child[target]);
                child[target] = 0;
              }
            });
        });
        return n;
      }
      return oldData;
    });
  };
};

export const usePostTicketAttachment = () => {
  const [progress, setProgress] = useState(null);
  return {
    ...useMutation(
      ({ attachment }) => {
        const formData = new FormData();
        formData.append("attachment", attachment);
        return api.post(`/tickets/add_attachment`, formData, {
          onUploadProgress({ loaded, total }) {
            // Stop to 80% to fake the zendesk upload (after our db save)
            const percentCompleted = Math.round((loaded * 100) / total);
            const fakePercent = (81 * percentCompleted) / 100;
            setProgress(fakePercent);
          },
          timeout: 0,
        });
      },
      {
        onSuccess: ({ data }) => {
          setProgress(null);
        },
      }
    ),
    progress,
  };
};

export const useGetDeleteAccount = () => useFetch("/account/delete_account");

export const useDeleteAccount = () =>
  useMutation((values) => api.delete("/account", { params: values }), {
    onSuccess: () => {
      window.location.href = "/";
    },
  });

export const useGetAccountCompany = () => useFetch("/account/company");

export const useDeleteDateTimeChange = () => {
  const { id } = useParams();
  const updateByKey = useUpdateByKey(`/inboxes/${id}`);
  const toast = useToast();
  return useMutation(
    (v) =>
      api.delete(`/jobs/${v.id}/date_time_change_requests/${v.date_time_id}`),
    {
      onSuccess: ({ data }) => {
        updateByKey(data);
      },
      onError: (err) => toast.danger(err.response.data.error_message),
    }
  );
};

export const usePutCompany = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(({ values }) => api.put("/account/company", values), {
    onSuccess: (_, { values, resetForm }) => {
      toast.success(polyglot.t("common.success.profile_update"));
      queryClient.setQueryData(["/account/company"], () => ({
        ...values,
        street_address: values.address,
      }));
      resetForm({
        values,
      });
    },
    onError: (err) => toast.danger(err.response.data.error_message),
  });
};

export const useGetDateTimeChange = (params, options) =>
  useFetch(`/jobs/${params.id}/date_time_change_requests/new`, {
    params: { date: params.date },
    queryKey: [
      `DATE_TIME_CHANGE_REQUEST`,
      {
        id: params.id,
        date: formatDate(params.date, "YYYY-MM", "YYYY-MM-DD"),
      },
    ],
    ...options,
  });

export const useCreateDateTimeRequest = ({
  id: discussable_id,
  discussionId,
  onClose,
  redirect,
}) => {
  const updateByKey = useUpdateByKey(`/inboxes/${discussionId}`);
  return useMutation(
    ({ date, start_hour, comment }) =>
      api.post(`/jobs/${discussable_id}/date_time_change_requests`, {
        date,
        start_hour,
        comment,
      }),
    {
      onSuccess: ({ data }) => {
        if (redirect) window.location = `/inboxes/${data.data.id}`;
        else {
          if (discussionId) updateByKey(data);
          onClose();
        }
      },
    }
  );
};

export const usePutEmailVerification = () => {
  const toast = useToast();
  return useMutation(
    () => api.put("/account/informations/send_email_verification"),
    {
      onError: (err) => toast.danger(err.response.data.error_message),
    }
  );
};

export const usePutAccountInformations = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const key = "/account/informations";
  return useMutation(
    ({ values, name }) =>
      api.put(`/account/informations/update_${name}`, values),
    {
      onSuccess: (_, variables) => {
        if (queryClient.getQueryData([key])) {
          queryClient.setQueryData([key], (oldData) => {
            const newData = {
              ...oldData,
              user: { ...oldData.user, ...variables.values },
            };
            if (variables.name === "email") {
              newData.user.email_confirmed = false;
            }
            return newData;
          });
          toast.success(polyglot.t("common.success.profile_update"));
        } else {
          window.location.reload();
        }
      },
      onError: (err) => {
        toast.danger(err.response.data.error_message);
      },
    }
  );
};

export const useGetHome = () => useFetch("/home");

export const useGetHomeCategory = () =>
  useFetch("/home/category", { queryKey: ["/home/category", { id: null }] });

export const useUpdateHomeAddress = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation((v) => api.put("/home/update_address", v), {
    onSuccess: () => {
      // refresh all req
      queryClient.resetQueries({
        queryKey: "/home/category",
        predicate: (query) => query.queryKey[0]?.[0] === "/home/category",
        exact: false,
      });
      queryClient.invalidateQueries(["/home"]);
    },
    onError: (err) => {
      toast.danger(err.response.data.error_message);
    },
  });
};

export const useGetInfiniteHomeCategory = (id) =>
  useInfiniteFetch(`/home/category?category=${id}`, null, {
    queryKey: ["/home/category", { id }],
  });

export const useGetNotifications = () => {
  useForceRefresh("/notifications", "notifications_count");
  const removeBadge = useRemoveBadges();
  return useInfiniteFetch("/notifications", null, {
    onSuccess: () => {
      removeBadge("notifications_count");
    },
  });
};
export const useGetAccountBillings = () => useInfiniteFetch("/account/billing");
export const useGetAccountManageNotifications = () =>
  useFetch("/account/notifications");

export const usePutAccountManageNotifications = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    (values) => {
      api.put("/account/notifications/update_alert_settings", values);
    },

    {
      onSuccess: (_, variables) => {
        toast.remove();
        queryClient.invalidateQueries(["/account/notifications"]);
        queryClient.setQueryData(["/account/notifications"], (old) => {
          old.categories.map((r) =>
            r.options.map((opt) => ({
              ...opt,
              value: variables[opt.parameter],
            }))
          );
          return old;
        });
        toast.success(polyglot.t("common.success.profile_update"));
      },
      onError: () => {
        toast.danger(polyglot.t("common.problem_has_occurred"));
      },
    }
  );
};

export const useGetAccountInformations = () =>
  useFetch("/account/informations");

export const useGetAccountWallet = () => useInfiniteFetch("/account/wallet");

export const useGetAccountCreditCard = (_, options) =>
  useFetch("/account/wallet/get_card", { staleTime: 600000, ...options });

export const useDeleteAccountCreditCard = ({ closeModal }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(() => api.delete("/account/wallet/remove_card"), {
    onSuccess: () => {
      closeModal();
      toast.success(polyglot.t("wallet.payment_method_removed_success"));
      queryClient.setQueryData([ACCOUNT_WALLET_PATH], (old) => {
        const n = old;
        if (n.pages[n.pages.length - 1]?.data) {
          n.pages[n.pages.length - 1].data.has_credit_card = false;
        }
        return n;
      });
    },
    onError: () => {
      toast.danger(polyglot.t("common.problem_has_occurred"));
    },
  });
};

export const useGetAccountCesu = () => useInfiniteFetch("/account/cesu");

export const useGetInboxes = () => {
  useForceRefresh("/inboxes", "messages_count");
  return useInfiniteFetch("/inboxes");
};

export const useGetTickets = () => {
  useForceRefresh("/tickets", "tickets_count");
  return useInfiniteFetch("/tickets");
};

export const useGetTicketMessages = () => {
  const { id } = useParams();
  const updateUnread = useUpdateUnread();
  return useFetch(`/tickets/${id}`, {
    onSuccess: () => {
      updateUnread(
        `/tickets`,
        ["tickets_opened", "tickets_closed"],
        "unread_messages",
        "tickets_count"
      );
    },
  });
};
export const useGetJobs = (params) =>
  useInfiniteFetch("/dashboard/jobs", params, {
    queryKey: ["/dashboard/jobs", params],
  });

export const useGetYoojoDirectJobs = () =>
  useFetch("/dashboard/yoojo_direct", {
    queryKey: ["/dashboard/jobs", "yoojo_direct"],
  });

export const useGetMessages = ({ id }) => {
  const updateUnread = useUpdateUnread();
  return useFetch(`/inboxes/${id}`, {
    onSuccess: () => {
      updateUnread(
        `/inboxes`,
        ["discussions", "archived_discussions"],
        "msg_unread",
        "messages_count"
      );
    },
    refetchInterval: 5000,
  });
};
export const usePostChatMessage = () => {
  const { id } = useParams();
  const updateByKey = useUpdateByKey(`/inboxes/${id}`);
  return useMutation((v) => api.post(`/inboxes/${id}/create_message`, v), {
    onSuccess: ({ data }) => {
      updateByKey(data);
    },
  });
};

export const useGetReferral = () => useFetch(REFERRAL_PATH);

export const usePutShareReferral = () =>
  useMutation(() => api.put("/invite-friends/share"));

export const useGetTaxes = () => useFetch(ACCOUNT_TAXES_PATH);
export const usePutAutomaticDeclarative = () =>
  useMutation(({ automatic_declarative }) =>
    api.put("/account/taxes/update_automatic_declarative", {
      automatic_declarative,
    })
  );

export const usePutTaxesAccount = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    (values) => api.put(`users/update_urssaf_informations`, values),
    {
      onSuccess: (_, variables) => {
        queryClient.setQueryData([ACCOUNT_TAXES_PATH], (old) => ({
          ...old,
          user: { ...old.user, ...variables },
        }));
        toast.success("Votre profil a été mis a jour.");
      },
      onError: (err) => toast.danger(err.response.data.error_message),
    }
  );
};
