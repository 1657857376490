import { useMutation } from "@tanstack/react-query";
import { api } from "../../utils";
import polyglot from "../../utils/polyglot";
import { useFetch, useToast } from "../hooks";

export const useGetExtraRatingUser = ({ offerId }) =>
  useFetch(`offers/${offerId}/extra_rates/new`);

export const useCreateExtraRating = () => {
  const toast = useToast();
  return useMutation(
    ({ offerId, values }) => api.post(`offers/${offerId}/extra_rates`, values),
    {
      onSuccess: () => {
        toast.success(polyglot.t("extra_rating.success.toast"));
      },
      onError: () => {
        toast.error(polyglot.t("common.problem_has_occurred"));
      },
    }
  );
};
