import { FastField, useFormikContext } from "formik";
import { Alert, Icon, PhoneInput } from "../../../components";
import polyglot from "../../../utils/polyglot";
import { StyledFormControl } from "./fields";
import StepTitle from "./StepTitle";

const PhoneNumber = ({ title }) => {
  const { values } = useFormikContext();

  return (
    <>
      <StepTitle>
        {title ||
          polyglot.t("categories.what_phone_number_jobber_can_contact_plural", {
            count: values.nb_jobbers,
          })}
      </StepTitle>
      <FastField name="phone">
        {({ field, form }) => (
          <StyledFormControl>
            <PhoneInput
              {...field}
              onChange={(v) => form.setFieldValue("phone", v)}
            />
          </StyledFormControl>
        )}
      </FastField>
      <Alert.Low renderIcon={() => <Icon.XLarge name="shield-check-solid" />}>
        {polyglot.t("categories.private_informations_are_confidentials")}
      </Alert.Low>
    </>
  );
};

export default PhoneNumber;
