import { Form, Formik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { MemoryRouter } from "react-router-dom";
import * as yup from "yup";
import { colors, spacings } from "../../assets/themes";
import { Block, Button, Flow, Modal, Spinner } from "../../components";
import Progress from "../../components/progress";
import {
  useCreateExtraRating,
  useGetExtraRatingUser,
} from "../../modules/routes/extra-rating-routes";
import polyglot from "../../utils/polyglot";
import ExtraRatingIntro from "./ExtraRatingIntro";
import ExtraRatingPage from "./ExtraRatingPage";
import ExtraRatingProblemPage from "./ExtraRatingProblemsPage";

const MIN_HEIGHT = { xs: "100%", md: "70vh" };

const ExtraRatingModal = ({ offer_id }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const { isLoading, data } = useGetExtraRatingUser({
    offerId: offer_id,
  });
  const createExtraRating = useCreateExtraRating();
  const formikRef = useRef();
  const flowRef = useRef();

  const onClose = () => {
    setIsOpen(false);
  };

  const getPages = useCallback(() => {
    if (!isLoading && data)
      return [
        {
          path: "intro",
          View: ExtraRatingIntro,
          customProps: {
            onClose,
            goBack: () => {
              flowRef.current?.goBack();
            },
            jobber: data?.jobber || {},
          },
          validationSchema: yup.object().shape({}),
        },
        {
          path: "punctuality",
          View: ExtraRatingPage,
          title: polyglot.t("extra_rating.punctuality.title"),
          customProps: {
            onClose,
            goBack: () => {
              flowRef.current?.goBack();
            },
            fields: [
              {
                title: polyglot.t("extra_rating.punctuality.good"),
                name: "punctuality",
                subtitle: polyglot.t(
                  "extra_rating.punctuality.good_description"
                ),
                value: "good",
              },
              {
                title: polyglot.t("extra_rating.punctuality.average"),
                name: "punctuality",
                subtitle: polyglot.t(
                  "extra_rating.punctuality.average_description"
                ),
                value: "average",
              },
              {
                title: polyglot.t("extra_rating.punctuality.bad"),
                name: "punctuality",
                subtitle: polyglot.t(
                  "extra_rating.punctuality.bad_description"
                ),
                value: "bad",
              },
            ],
          },
          nextLabel: polyglot.t("common.next"),
          validationSchema: yup.object().shape({
            punctuality: yup.string().required(),
          }),
        },
        {
          path: "interpersonal",
          View: ExtraRatingPage,
          nextLabel: polyglot.t("common.next"),
          title: polyglot.t("extra_rating.interpersonal.title"),
          customProps: {
            onClose,
            goBack: () => {
              flowRef.current?.goBack();
            },
            fields: [
              {
                title: polyglot.t("extra_rating.interpersonal.good"),
                name: "interpersonal",
                subtitle: polyglot.t(
                  "extra_rating.interpersonal.good_description"
                ),
                value: "good",
              },
              {
                title: polyglot.t("extra_rating.interpersonal.average"),
                name: "interpersonal",
                subtitle: polyglot.t(
                  "extra_rating.interpersonal.average_description"
                ),
                value: "average",
              },
              {
                title: polyglot.t("extra_rating.interpersonal.bad"),
                name: "interpersonal",
                subtitle: polyglot.t(
                  "extra_rating.interpersonal.bad_description"
                ),
                value: "bad",
              },
            ],
          },
          validationSchema: yup.object().shape({
            interpersonal: yup.string().required(),
          }),
        },
        {
          path: "pricing",
          View: ExtraRatingPage,
          nextLabel: polyglot.t("common.next"),
          title: polyglot.t("extra_rating.pricing.title"),
          customProps: {
            onClose,
            goBack: () => {
              flowRef.current?.goBack();
            },
            fields: [
              {
                title: polyglot.t("extra_rating.pricing.good"),
                name: "pricing",
                subtitle: polyglot.t("extra_rating.pricing.good_description"),
                value: "good",
              },
              {
                title: polyglot.t("extra_rating.pricing.bad"),
                name: "pricing",
                subtitle: polyglot.t("extra_rating.pricing.bad_description"),
                value: "bad",
              },
            ],
          },
          validationSchema: yup.object().shape({
            pricing: yup.string().required(),
          }),
        },
        {
          path: "problems",
          View: ExtraRatingProblemPage,
          nextLabel: polyglot.t("common.finish"),
          title: polyglot.t("exta_rating.problems.title"),
          customProps: {
            onClose,
            goBack: () => {
              flowRef.current?.goBack();
            },
          },
          validationSchema: yup.object().shape({
            is_valid: yup
              .boolean()
              .test("at-least-one-true", "", (_, context) => {
                if (context.parent.has_problem === false) return true;
                if (context.parent.has_problem === true) {
                  return (
                    context.parent.forces_payment_confirmation ||
                    context.parent.asks_more_money ||
                    context.parent.forces_furnitures_payment ||
                    context.parent.innapropriate_behavior ||
                    context.parent.not_focused ||
                    context.parent.uses_drugs ||
                    (context.parent.other_boolean === true &&
                      context.parent.other_report?.length > 1)
                  );
                }
                return false;
              }),
          }),
        },
      ];
    return [];
  }, [isLoading, data]);

  const handleStepChange = (currIndex) => {
    setActiveIndex(currIndex);
  };

  const handleSubmit = (values) => {
    createExtraRating.mutate({ offerId: offer_id, values });
  };

  useEffect(() => {
    if (createExtraRating?.isSuccess) onClose();
  }, [createExtraRating?.isSuccess]);

  useEffect(() => {
    formikRef.current?.validateForm();
  }, [activeIndex]);

  return (
    <Modal.Small isOpen={isOpen} fullScreenOnMobile onClose={onClose}>
      <MemoryRouter>
        {!isLoading && data ? (
          <Formik
            initialValues={{
              punctuality: null,
              interpersonal: null,
              forces_payment_confirmation: false,
              asks_more_money: false,
              forces_furnitures_payment: false,
              innapropriate_behavior: false,
              not_focused: false,
              uses_drugs: false,
              // UI ONLY
              has_problem: null,
              other_boolean: false,
              // END UI ONLY
              other_report: "",
            }}
            validateOnMount
            innerRef={formikRef}
            onSubmit={handleSubmit}
            validationSchema={() =>
              yup.lazy(() => getPages()[activeIndex].validationSchema)
            }
          >
            {({ isValid }) => (
              <Form
                css={`
                  height: 100%;
                `}
              >
                <Block
                  minHeight={MIN_HEIGHT}
                  overflow="hidden"
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <Flow.Router
                    renderProgress={() => null}
                    onChange={handleStepChange}
                    routes={getPages()}
                    ref={flowRef}
                  />
                  <Block
                    position="sticky"
                    bottom="0"
                    display="flex"
                    alignItems="stretch"
                    flexDirection="column"
                    backgroundColot={colors.background}
                  >
                    {activeIndex >= 1 && (
                      <>
                        <Progress.Bar
                          steps={getPages().length - 1}
                          value={(100 / (getPages().length - 1)) * activeIndex}
                          css={`
                            width: 100%;
                            margin-bottom: ${spacings.m};
                          `}
                        />
                        <Modal.Item.Wrapper>
                          <Button.Large
                            disabled={!isValid}
                            isLoading={createExtraRating.isLoading}
                            type={
                              getPages()[activeIndex + 1] ? "button" : "submit"
                            }
                            onClick={
                              getPages()[activeIndex + 1]
                                ? flowRef.current?.goNext
                                : null
                            }
                          >
                            {getPages()[activeIndex].nextLabel}
                          </Button.Large>
                        </Modal.Item.Wrapper>
                      </>
                    )}
                  </Block>
                </Block>
              </Form>
            )}
          </Formik>
        ) : (
          <Block
            minHeight={MIN_HEIGHT}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner.Medium />
          </Block>
        )}
      </MemoryRouter>
    </Modal.Small>
  );
};
export default ExtraRatingModal;
