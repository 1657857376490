import { artwork } from "../../assets/categories";
import { spacings } from "../../assets/themes";
import { Button, List, Modal, Shape } from "../../components";
import { SHAPE } from "../../components/Styles/variants";
import { CATEGORIES_PATH } from "../../config/categories";
import { getCategoryFormUrlById } from "../../utils";
import capitalize from "../../utils/capitalize";
import polyglot from "../../utils/polyglot";

const categoriesCustom = CATEGORIES_PATH.map((path) => {
  const firstLvlCustomCat = path.children?.find((c) => c.isCustom);
  if (firstLvlCustomCat) {
    return { label: path.id, value: firstLvlCustomCat.id };
  }
  return path.children.map((subCat) => ({
    label: subCat.id,
    value: subCat.children?.find((c) => c.isCustom).id,
  }));
})
  .flat()
  .filter((el) => typeof el.value !== "undefined");

const SelectCategoryModal = ({ isOpen, onClose, urlParams, ids = [] }) => {
  const getFilteredCategories = () =>
    ids.length > 0
      ? categoriesCustom.filter((cat) => ids.includes(cat.value))
      : categoriesCustom;
  const handleClose = () => {
    if (onClose) onClose();
  };
  return (
    <Modal.Small isOpen={isOpen} onClose={handleClose} fullScreenOnMobile>
      <Modal.Item.Header onClose={handleClose}>
        <Modal.Item.Title>
          {polyglot.t("categories_modal.select_category")}
        </Modal.Item.Title>
      </Modal.Item.Header>
      {getFilteredCategories().map((cat) => (
        <List.Item
          withGutters
          key={cat.value}
          LeftComponent={() => (
            <Shape.Small
              shape={SHAPE.SHAPE.ROUND}
              backgroundColor="transparent"
              css={`
                overflow: hidden;
                margin: ${spacings.xs} 0;
              `}
            >
              <img
                src={artwork[cat.value]}
                alt={capitalize(polyglot.t(`categories.${cat.label}.name`))}
                css={`
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: right bottom;
                `}
              />
            </Shape.Small>
          )}
          href={getCategoryFormUrlById(cat.value, urlParams)}
          chevron
        >
          <List.Elem.Title className="font-size-sm-4">
            {capitalize(polyglot.t(`categories.${cat.label}.name`))}
          </List.Elem.Title>
        </List.Item>
      ))}
      <Modal.Item.Wrapper>
        <Button.Large kind="secondary" className="mt-3" onClick={handleClose}>
          {polyglot.t("common.cancel")}
        </Button.Large>
      </Modal.Item.Wrapper>
    </Modal.Small>
  );
};

export default SelectCategoryModal;
