import { useMutation } from "@tanstack/react-query";
import { getQueryKey } from "../../pages-admin/UserPreview/config";
import { api } from "../../utils";
import { useFetch } from "../hooks";

export const useGetUserData = ({ id, kind }, options) =>
  useFetch(`/kiss/snippets/${id}/${kind}`, {
    queryKey: getQueryKey({ id, kind }),
    ...options,
  });

export const useLogAsUser = () =>
  useMutation(({ id }) => api.post(`/kiss/users/${id}/create_session`), {
    onSuccess: ({ data: { query_strings, domain } }, { redirect_url }) => {
      const url = new URL(redirect_url, domain);
      const searchParams = new URLSearchParams(query_strings || {});
      url.search = searchParams.toString();
      window.open(url.href, "_blank");
    },
  });
