import { FastField } from "formik";
import * as yup from "yup";
import polyglot from "../../../utils/polyglot";
import { FormSelectables, StyledFormControl } from "../common/fields";
import { INFORMATIONS_PATH } from "../constants";

const config = {
  isDateRange: true,
  noTime: true,
  forceSummaryColumn: true,
  IsNbHoursReadOnly: true,
};

const JOBBER_HOME = "jobber_home";
const POSTER_HOME = "poster_home";
const VISITS = "visits";

const validationSchema = yup.object().shape({
  nh_specific: yup.string().required(),
  nh_specific3: yup.mixed().when(["nh_specific"], {
    is: VISITS,
    then: yup.mixed().required(),
    otherwise: yup.mixed().notRequired(),
  }),
});

export function useCategory5006() {
  const initialValues = {
    nh_specific: "",
    nh_specific3: "",
  };

  const getNbHours = () => undefined;

  const nhConfig = {
    nh_specific: {
      options: [
        { value: JOBBER_HOME, label: polyglot.t("categories.at_jobber_home") },
        { value: POSTER_HOME, label: polyglot.t("categories.at_owner_home") },
        { value: VISITS, label: polyglot.t("categories.visits") },
      ],
    },
    nh_specific3: {
      label: polyglot.t("categories.number_daily_visits"),
      options: [
        { value: 1.0, label: "1" },
        { value: 2.0, label: "2" },
        { value: 3.0, label: "3" },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.where_petsitting_place"),
      validationSchema,
      View: () => (
        <>
          <StyledFormControl>
            <FormSelectables
              isWrap
              name="nh_specific"
              options={nhConfig.nh_specific.options}
              onChange={(_, { setFieldValue }) => {
                setFieldValue("nh_specific3", "");
              }}
            />
          </StyledFormControl>
          <FastField>
            {({ form }) =>
              form.values.nh_specific === VISITS && (
                <div data-test="visits-options">
                  <StyledFormControl label={nhConfig.nh_specific3.label}>
                    <FormSelectables
                      name="nh_specific3"
                      options={nhConfig.nh_specific3.options}
                    />
                  </StyledFormControl>
                </div>
              )
            }
          </FastField>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    config,
    getNbHours,
  };
}
