import React, { useEffect, useRef } from "react";
import { colors, radius, spacings } from "../../assets/themes";
import { Body16, Button, Icon } from "../../components";
import Block from "../../components/Block";
import { BUTTON } from "../../components/Styles/variants";
import { useWindowSize } from "../../modules/hooks";
import { formatPhone } from "../../utils";
import polyglot from "../../utils/polyglot";
import scrollToBottom from "../../utils/scroll-to-bottom";
import ChatMessage from "./ChatMessage";

const Messages = ({
  messages,
  users,
  discussable_id,
  discussable_url,
  id,
  current_user,
  show_message_form,
}) => {
  const messagesWrapperRef = useRef();
  const { height: windowHeight } = useWindowSize();
  const renderAlert = () => {
    const msgOnly = messages.filter((message) => message.kind === "message");
    const noMessagesFromOthers =
      msgOnly.filter(
        (message) => !message.user.is_lucy && !message.user.is_current_user
      ).length === 0;
    const shouldShowAlert =
      noMessagesFromOthers && msgOnly[0].user.is_current_user;
    const isMultipleJobbers = users.length > 1;
    const texts = {
      multiple: {
        title: polyglot.t("chat.bot_answer.title.multiple"),
        body: polyglot.t("chat.bot_answer.body.multiple"),
      },
      single: {
        title: polyglot.t("chat.bot_answer.title.single", {
          first_name: users[0].first_name,
        }),
        body: polyglot.t("chat.bot_answer.body.single", {
          first_name: users[0].first_name,
        }),
      },
    };
    const getTexts = () => (isMultipleJobbers ? texts.multiple : texts.single);
    return shouldShowAlert ? (
      <Block
        borderRadius={radius.l}
        backgroundColor={colors.gray50}
        padding={spacings.m}
        maxWidth="600px"
        margin={`${spacings.m} auto 0 auto`}
      >
        <Body16
          strong
          css={`
            margin-bottom: ${spacings.xs};
          `}
        >
          {getTexts().title}
        </Body16>
        <Body16 color={colors.muted}>{getTexts().body}</Body16>
        {!isMultipleJobbers && (
          <Button.Small
            css={`
              margin-top: ${spacings.s};
            `}
            href={`tel:${users[0].phone}`}
            kind={BUTTON.KIND.SECONDARY}
            shape={BUTTON.SHAPE.PILL}
            LeftComponent={() => <Icon.Medium name="phone-solid" />}
          >
            {formatPhone(users[0].phone)}
          </Button.Small>
        )}
      </Block>
    ) : null;
  };

  useEffect(() => {
    scrollToBottom(messagesWrapperRef);
  }, []);

  useEffect(() => {
    scrollToBottom(messagesWrapperRef);
  }, [messages.length, windowHeight]);

  const renderMessage = () => {
    const isSeenBy = users.filter((user) => user.last_message_read);
    const renderTimeState = (created_at, is_current_user, isLast) => {
      if (isLast && is_current_user) {
        if (isSeenBy.length === users.length && users.length > 1) {
          return polyglot.t("chat.seen_by_everybody");
        }
        if (isSeenBy.length > 0) {
          return `${polyglot.t("chat.seen_by")} ${Object.values(
            isSeenBy.map((user) => user.first_name)
          )
            .toString()
            .replace(/,/g, ", ")}`;
        }
      }
      return `${polyglot.t("chat.sended")} ${created_at}`;
    };

    return messages.map((message, index) => {
      const forceMainTimestamp =
        index === messages.length - 1 ||
        message?.date !== messages[index + 1]?.date;

      const offerId = users?.find((u) => u?.id === message?.user?.id)?.offer_id;

      const isLastGroup =
        !message?.user ||
        !messages[index - 1]?.user ||
        messages[index - 1]?.user.id !== message?.user?.id ||
        messages[index - 1]?.date !== message?.date;

      const isFirstGroup =
        !message?.user ||
        !messages[index + 1]?.user ||
        messages[index + 1]?.date !== message?.date ||
        (message?.user?.id &&
          messages[index + 1]?.user.id !== message?.user?.id);

      return (
        <ChatMessage
          {...message}
          offerId={offerId}
          key={message?.id}
          discussable_id={discussable_id}
          discussable_url={discussable_url}
          action={message?.action}
          id={id}
          isLast={index === 0}
          current_user={current_user}
          forceMainTimestamp={forceMainTimestamp}
          stacked={{
            middle: !isFirstGroup && !isLastGroup,
            last: isLastGroup,
            first: isFirstGroup,
          }}
          own={message?.user?.is_current_user}
          customTimestamp={renderTimeState(
            message?.created_at,
            message?.user?.is_current_user,
            index === 0
          )}
        />
      );
    });
  };

  return (
    <Block overflowY="auto" flex="1">
      {messages.length > 0 && (
        <div ref={messagesWrapperRef}>
          <Block
            flexDirection="column-reverse"
            display="flex"
            padding={spacings.m}
          >
            {users.length > 0 && show_message_form && renderAlert()}
            {renderMessage()}
          </Block>
        </div>
      )}
    </Block>
  );
};

export default Messages;
