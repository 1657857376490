import React from "react";
import noResults from "../../assets/images/svg-icons/askService/noResults.svg";
import { borderWidth, colors, shadows, spacings } from "../../assets/themes";
import { Block, List } from "../../components";
import { LIST } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";

const CustomAskItem = React.memo(
  ({ href, onClick }) => (
    <Block marginTop={spacings.sm}>
      <List.Item
        divider={false}
        href={href}
        shape={LIST.SHAPE.ROUND}
        chevron
        onClick={onClick}
        withGutters
        css={`
          box-shadow: ${shadows.xs};
          border: solid ${borderWidth.s} ${colors.border};
        `}
        LeftComponent={() => (
          <img
            src={noResults}
            width={48}
            height={48}
            className="mr-2"
            style={{ borderRadius: "var(--radius-m)" }}
            alt={polyglot.t("categories_modal.create_custom_job")}
          />
        )}
      >
        <List.Elem.Subtitle className="font-size-2 font-size-sm-3">
          {polyglot.t("categories_modal.cant_find_category_question")}
        </List.Elem.Subtitle>
        <List.Elem.Title className="font-size-sm-4 font-weight-medium">
          {polyglot.t("categories_modal.create_custom_job")}
        </List.Elem.Title>
      </List.Item>
    </Block>
  ),
  (prevProps, nextProps) => prevProps.isLoading === nextProps.isLoading
);
export default CustomAskItem;
