import _ from "lodash";
import moment from "moment";
import { isToday, isTomorrow } from ".";
import { colors } from "../assets/themes";
import { Body14 } from "../components";
import { JOB_MAX_HOUR, JOB_MIN_HOUR } from "./constants";
import polyglot from "./polyglot";

const generateHoursArray = (from, to) => _.range(from, to + 0.5, 0.5);

const getDefaultHours = (
  selectedDate,
  format,
  isAdmin,
  { min = JOB_MIN_HOUR, max = JOB_MAX_HOUR, ignoreCurrentHour = false }
) => {
  const today = new Date();
  const currentHour = today.getHours();
  if (selectedDate) {
    if (ignoreCurrentHour) {
      return generateHoursArray(min, max);
    }
    if (isToday(selectedDate, format)) {
      if (isAdmin) {
        return generateHoursArray(currentHour + 1, max);
      }
      if (currentHour <= 8) {
        return generateHoursArray(13, max);
      }
      if (currentHour === 9) {
        return generateHoursArray(14, max);
      }
      if (currentHour === 10) {
        return generateHoursArray(15, max);
      }
      if (currentHour === 11) {
        return generateHoursArray(16, max);
      }
      if (currentHour === 12) {
        return generateHoursArray(17, max);
      }
      if (currentHour === 13) {
        return generateHoursArray(18, max);
      }
      if (currentHour === 14) {
        return generateHoursArray(19, max);
      }
      if (currentHour === 15) {
        return generateHoursArray(20, max);
      }
      if (currentHour === 16) {
        return generateHoursArray(max, max);
      }
      return generateHoursArray(min, max);
    }
    if (isTomorrow(selectedDate, format)) {
      if (isAdmin) {
        return generateHoursArray(min, max);
      }
      if (currentHour <= 18) {
        return generateHoursArray(min, max);
      }
      if (currentHour === 19) {
        return generateHoursArray(9, max);
      }
      if (currentHour === 20) {
        return generateHoursArray(10, max);
      }
      if (currentHour === 21) {
        return generateHoursArray(11, max);
      }
      if (currentHour === 22) {
        return generateHoursArray(12, max);
      }
      if (currentHour === 23) {
        return generateHoursArray(13, max);
      }
      return generateHoursArray(min, max);
    }
  }
  return generateHoursArray(min, max);
};
export const getAvailableTimes = ({
  min = JOB_MIN_HOUR,
  max = JOB_MAX_HOUR,
}) => {
  let hrs = [];
  for (let i = min * 2; i <= max * 2; i += 1) {
    let n =
      i % 2 === 0
        ? { value: i / 2, label: `${i / 2}:00`.padStart(5, "0") }
        : {
            value: (i + 1) / 2 - 1 + 0.5,
            label: `${(i + 1) / 2 - 1}:30`.padStart(5, "0"),
          };
    if (n < 10) {
      n = `0${n}`.padStart(5, "0");
    }
    hrs = [...hrs, n];
  }
  return hrs;
};

/// EXPORTS ///

export const getMinDate = () => {
  const tomorrow = moment().add(1, "days");
  const currentHour = moment().hours();

  if (currentHour > 16) {
    return tomorrow.toDate();
  }

  return moment().toDate();
};

export const getHoursOptions = (
  date,
  format = "DD/MM/YYYY",
  isAdmin,
  config = {}
) => {
  let times;
  if (date) {
    const currentAvailabilities = config?.availabilities
      ? config?.availabilities[moment(date, format).format("YYYY-MM-DD")]
      : [];

    times = getAvailableTimes(config).map(({ label, value }) => {
      const isDisabled =
        (currentAvailabilities.length > 0
          ? !currentAvailabilities?.includes(value)
          : false) ||
        !getDefaultHours(date, format, isAdmin, config).includes(value);
      return {
        label,
        value,
        RightComponent: isDisabled
          ? () => (
              <Body14 color={colors.muted}>
                {polyglot.t("common.unavailable")}
              </Body14>
            )
          : null,
        disabled: isDisabled,
      };
    });
  } else {
    // Used in partners store
    times = getAvailableTimes({ min: config?.min, max: config?.max });
  }

  return times;
};
