import { Field, Form, Formik, useFormikContext } from "formik";
import Fuse from "fuse.js";
import { useEffect, useRef, useState } from "react";
import LazyLoad from "react-lazyload";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { colors, radius, sizes, spacings } from "../../../../assets/themes";
import {
  Block,
  Body16,
  Button,
  DatePicker,
  Divider,
  FormControl,
  Icon,
  Input,
  List,
  Modal,
  PhoneInput,
  Radio,
} from "../../../../components";
import { BUTTON, INPUT } from "../../../../components/Styles/variants";
import { useCategoryForm } from "../../../../modules/hooks";
import { useAddCustomer } from "../../../../modules/routes/category-forms-routes";
import {
  emailRegex,
  formatPhone,
  getDateOfBirthActiveMonth,
  getDateOfBirthMaxDate,
  phoneRegex,
} from "../../../../utils";
import polyglot from "../../../../utils/polyglot";
import { getCategoryFormQueryId } from "../../utils";

const validationSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  date_of_birth: yup.date().required(),
  email: yup.string().matches(emailRegex).notRequired(),
  phone: yup.string().matches(phoneRegex).required(),
});

const AddCustomerModal = ({ isOpen, onClose, onChange }) => {
  const formikRef = useRef();
  const addCustomer = useAddCustomer();

  useEffect(() => {
    if (addCustomer.isSuccess && onChange) {
      onChange(addCustomer.data?.data?.data, addCustomer.variables);
    }
  }, [addCustomer.isSuccess]);

  const handleSubmit = (values) => {
    addCustomer.mutate(values);
  };

  const handleClose = () => {
    formikRef.current?.resetForm();
    onClose();
  };

  return (
    <Modal.Small
      isOpen={isOpen}
      onClose={handleClose}
      shouldCloseOnOverlayClick={false}
    >
      <Modal.Item.Header onClose={handleClose}>
        <Modal.Item.Title>Ajouter un nouveau client</Modal.Item.Title>
      </Modal.Item.Header>
      <Formik
        validateOnMount
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        innerRef={formikRef}
        initialValues={{
          first_name: "",
          last_name: "",
          date_of_birth: "",
          email: "",
          phone: "",
        }}
      >
        {({ isValid }) => (
          <Form>
            <Modal.Item.Wrapper>
              <FormControl label="Prénom">
                <Field name="first_name">
                  {({ field }) => <Input {...field} placeholder="Jean" />}
                </Field>
              </FormControl>
              <FormControl label="Nom">
                <Field name="last_name">
                  {({ field }) => <Input {...field} placeholder="Dupond" />}
                </Field>
              </FormControl>
              <Field name="date_of_birth">
                {({ field, form }) => (
                  <FormControl label={polyglot.t("common.date_of_birth")}>
                    <DatePicker
                      {...field}
                      activeMonth={getDateOfBirthActiveMonth()}
                      onChange={(date) => form.setFieldValue(field.name, date)}
                      maxDate={getDateOfBirthMaxDate()}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="phone">
                {({ field, form }) => (
                  <FormControl label={polyglot.t("common.phone_number")}>
                    <PhoneInput
                      {...field}
                      onChange={(v) => form.setFieldValue("phone", v)}
                    />
                  </FormControl>
                )}
              </Field>
              <Block marginY={spacings.m}>
                <Divider.Cell />
              </Block>
              <FormControl label="Adresse e-mail (optionnel)">
                <Field name="email">
                  {({ field }) => (
                    <Input {...field} placeholder="jean@mail.com" />
                  )}
                </Field>
              </FormControl>
            </Modal.Item.Wrapper>
            <Modal.Item.Footer>
              <Button.Large onClick={handleClose} kind={BUTTON.KIND.MINIMAL}>
                Annuler
              </Button.Large>
              <Button.Large
                type="submit"
                disabled={!isValid}
                isLoading={addCustomer.isLoading}
              >
                Confirmer
              </Button.Large>
            </Modal.Item.Footer>
          </Form>
        )}
      </Formik>
    </Modal.Small>
  );
};

const FormFindCustomer = ({ name = "concierge_customer_id" }) => {
  const { concierge_customers, id } = useCategoryForm();
  const [customers, setCustomers] = useState(concierge_customers);
  const { setValues, values, touched, validateForm, setTouched } =
    useFormikContext();
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const fuse = new Fuse(concierge_customers, {
    keys: ["name", "email", "phone"],
  });

  useEffect(() => {
    setCustomers(concierge_customers);
  }, [concierge_customers]);

  const handleInputChange = (e) => {
    if (e.target.value?.length > 0) {
      const res = fuse.search(e.target.value);
      setCustomers(res.map((r) => r.item));
    } else {
      setCustomers(concierge_customers);
    }
  };

  const setVal = ({ id, name: customerName, phone }) => {
    setValues({
      ...values,
      concierge_customer_name: customerName,
      concierge_customer_phone: phone,
      [name]: id,
    });
    setTouched({
      ...touched,
      concierge_customer_name: true,
      concierge_customer_phone: phone,
      [name]: true,
    });
    setTimeout(() => {
      validateForm();
      //  TODO Fix this please. Need delay validate because it wont trigger on the first auto call
    }, 0);
  };
  const handleChange = (data, variables) => {
    setIsOpen(false);
    setVal({
      id: data?.id,
      name: `${variables.first_name} ${variables.last_name}`,
      phone: variables.phone,
    });
    queryClient.setQueryData([getCategoryFormQueryId({ id })], (old) => {
      const newData = old;
      const newVar = {
        name: `${variables.first_name} ${variables.last_name}`,
        date_of_birth: variables.date_of_birth,
        email: variables.email,
        phone: variables.phone,
        id: data?.id,
      };
      newData.concierge_customers = [newVar, ...old.concierge_customers];
      return newData;
    });
  };

  return (
    <>
      <Input
        shape={INPUT.SHAPE.CIRCLE}
        type="search"
        placeholder="Rechercher un client..."
        onChange={handleInputChange}
      />
      <Block marginTop={spacings.s}>
        <List.Item
          onClick={() => setIsOpen(true)}
          LeftComponent={() => (
            <Icon.Medium name="plus" color={colors.primary} />
          )}
        >
          <List.Elem.Title color={colors.primary} strong>
            Ajouter un nouveau client
          </List.Elem.Title>
        </List.Item>
      </Block>
      {customers?.length > 0 ? (
        customers?.map((customer, i) => (
          <LazyLoad
            height={72}
            key={`customer-id-${i}-${customer.id}`}
            placeholder={
              <Block
                borderRadius={radius.s}
                marginY={spacings.m}
                backgroundColor={colors.backgroundLight}
                width="100%"
                height={sizes.size72}
              />
            }
          >
            <List.Item
              onClick={() =>
                setVal({
                  id: customer.id,
                  name: customer.name,
                  phone: customer.phone,
                })
              }
              RightComponent={() => (
                <Radio checked={values[name] === customer.id} />
              )}
              LeftComponent={() => (
                <Icon.Large name="user" color={colors.primary} />
              )}
            >
              <List.Elem.Title strong>{customer.name}</List.Elem.Title>
              <List.Elem.Subtitle color={colors.muted}>
                {formatPhone(customer.phone)}{" "}
                {customer.email ? `• ${customer.email}` : ""}
              </List.Elem.Subtitle>
            </List.Item>
          </LazyLoad>
        ))
      ) : (
        <Block marginY={spacings.l}>
          <Body16 align="center" color={colors.muted}>
            Aucun client trouvé
          </Body16>
        </Block>
      )}
      <AddCustomerModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onChange={handleChange}
      />
    </>
  );
};

export default FormFindCustomer;
