import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { colors } from "../../assets/themes";
import { Body14, Button, ChatInput, Icon, Spinner } from "../../components";
import Block from "../../components/Block";
import { BUTTON } from "../../components/Styles/variants";
import { ChatContext } from "../../modules/contexts";
import {
  useGetMessages,
  usePostChatMessage,
  useCreateDateTimeRequest,
} from "../../modules/routes/dashboard-routes";
import polyglot from "../../utils/polyglot";
import ConversationHeader from "../dashboard/ConversationHeader";
import EditDateTime from "../EditDateTime";
import Messages from "./Messages";

const ChatBox = () => {
  const { id } = useParams();
  const targetRef = useRef();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { data, isLoading } = useGetMessages({ id });
  const canEditDateTime = !(
    !data?.date_time_changeable || data?.date_time_change_pending
  );
  const createDateTime = useCreateDateTimeRequest({
    onClose: () => setModalIsOpen(false),
    discussionId: id,
    id: !isLoading && data?.discussable_id,
  });
  const createMessage = usePostChatMessage();
  const handleSubmit = (values, bag) => {
    createMessage.mutate(values);
    bag.resetForm();
  };

  return (
    <>
      {!isLoading && data ? (
        <>
          <Block
            display="flex"
            alignItems="stretch"
            flexDirection="column"
            height="100%"
            key={`message-id-${data.messages.id}`}
          >
            <ConversationHeader
              users={data.users}
              title={data.discussable_title}
              url={data.discussable_url}
              discussable_id={data.discussable_id}
            />
            <div ref={targetRef} />
            <ChatContext.Provider
              value={{
                openDateTimeChange: () => setModalIsOpen(true),
                closeDateTimeChange: () => setModalIsOpen(false),
                canEditDateTime,
              }}
            >
              <Messages {...data} />
            </ChatContext.Provider>
            <Formik onSubmit={handleSubmit} initialValues={{ body: "" }}>
              {({ values, setFieldValue }) => (
                <Form>
                  <ChatInput
                    name="body"
                    value={values.body}
                    onChange={(e) => setFieldValue("body", e.target.value)}
                    disabled={!data.show_message_form}
                    placeholder={polyglot.t("tickets.message_placeholder")}
                    isLoading={createMessage.isLoading}
                    LeftComponent={
                      data.show_change_date_time
                        ? () => (
                            <Button.Medium
                              onClick={() => setModalIsOpen(true)}
                              disabled={!canEditDateTime}
                              kind={BUTTON.KIND.MINIMAL}
                              shape={BUTTON.SHAPE.CIRCLE}
                            >
                              <Icon.Large
                                name="calendar"
                                color={colors.primary}
                              />
                            </Button.Medium>
                          )
                        : undefined
                    }
                  >
                    {!data.show_message_form && (
                      <Body14
                        align="center"
                        color={colors.muted}
                        css={`
                          width: 100%;
                        `}
                      >
                        {polyglot.t("chat.no_input")}
                      </Body14>
                    )}
                  </ChatInput>
                </Form>
              )}
            </Formik>
          </Block>
          {canEditDateTime && modalIsOpen && (
            <EditDateTime
              isOpen={modalIsOpen}
              onClose={() => setModalIsOpen(false)}
              query={createDateTime}
              isBooked
              discussable_title={data.discussable_title}
              discussable_id={data.discussable_id}
              job_date={data.job_date}
            />
          )}
        </>
      ) : (
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <Spinner.Medium />
        </Block>
      )}
    </>
  );
};

export default ChatBox;
