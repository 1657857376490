import { useMutation } from "@tanstack/react-query";
import { getInstantPaymentOfferUrl } from "@/pages/Job/utils";
import { useFetch, useToast } from "../hooks";
import { api } from "@/utils";

export const useGetOfferInstantPayment = ({ id, price_per_hour }) =>
  useFetch(getInstantPaymentOfferUrl(id), {
    params: { price_per_hour },
    queryKey: "INSTANT_PAYMENT",
  });

export const useUpdateOfferInstantPayment = ({ id }) =>
  useMutation(({ price_per_hour }) =>
    api.get(getInstantPaymentOfferUrl(id), {
      params: { price_per_hour, mdr: "LOL" },
    })
  );

export const usePayInstant = ({ id }) => {
  const toast = useToast();
  return useMutation(
    (values) =>
      api.post(`/instant_payment_relations/${id}/instant_payments`, values),
    {
      onSuccess: ({ data }) => {
        if (data.data.secure_url) {
          window.location = data.data.secure_url;
        } else if (data?.data?.redirect_url) {
          window.location = data.data.redirect_url;
        }
      },
      onError: (err) => {
        toast.danger(err.response.data.error_message);
      },
    }
  );
};
