import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import * as yup from "yup";
import { AnimatePresence, motion } from "framer-motion";
import { colors, radius, sizes, spacings } from "../../assets/themes";
import {
  Block,
  Body14,
  Body16,
  Button,
  Col,
  Divider,
  FormControl,
  H3,
  H4,
  H5,
  Icon,
  Row,
  Selectable,
  Textarea,
} from "../../components";
import { BUTTON, SELECTABLE } from "../../components/Styles/variants";
import { useBreakpoints } from "../../modules/hooks";
import { useEditRateJobber, useRateJobber } from "../../modules/routes";
import polyglot from "../../utils/polyglot";

const validationSchema = yup.object().shape({
  rate: yup.number().min(1).required(),
  badge: yup.string(),
  improvments: yup.array().of(yup.string()),
  complaints: yup.array().of(yup.string()),
  bad_skills: yup.array().of(yup.string()),
  private_note: yup.string(),
  comment: yup.string().required(),
});

const StyledSelectableWrapper = styled.div`
  flex: 1;
  .ks-cboxtags .ks-cboxtags-wrapper label {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${spacings.xs};
    height: 100%;
    background-color: ${colors.transparent};
    border: solid 2px ${colors.transparent};
    border-radius: ${radius.ml};
  }
`;

const StyledRatingButton = styled(Button.Large)`
  ${({ active, rate }) => {
    const renderColor = () => {
      if (rate >= 4) return colors.warning;
      if (rate >= 3) return colors.orangered500;
      return colors.danger;
    };
    return css`
      border: none;
      background: none !important;
      transform: scale(1);
      transition: transform 0.1s ease-in-out;
      &:hover&:not(:active) {
        transform: scale(1.3);
        transition: transform 0.1s ease-in-out;
      }
      * {
        color: ${active ? renderColor() : "var(--color-gray400)"};
      }
    `;
  }}
`;

const getRatingTaglineByRate = (rate, { first_name }) => {
  if (rate >= 5) {
    return polyglot.t("rating.tagline.5");
  }
  if (rate >= 4) {
    return polyglot.t("rating.tagline.4", { first_name });
  }
  if (rate > 0) {
    return polyglot.t("rating.tagline.3");
  }
  return polyglot.t("rating.tagline.placeholder");
};

const JobberRating = ({
  offer_id,
  rate,
  jobber,
  badges_list,
  improvments_list,
  complaints_list,
  isEdit,
  onSuccess,
}) => {
  const rateJobber = useRateJobber();
  const editRateJobber = useEditRateJobber();
  const formRef = useRef();
  const breakpoints = useBreakpoints();
  const [privateNoteIsVisible, setPrivateNoteIsVisible] = useState(false);

  const handleRate = (selectedRate) => {
    setPrivateNoteIsVisible(false);
    formRef.current?.setFieldValue("rate", selectedRate);
    if (formRef.current?.values.complaints && selectedRate >= 4) {
      formRef.current?.setFieldValue("complaints", []);
    } else if (formRef.current?.values.rate === 4) {
      formRef.current?.setFieldValue("improvments", []);
    } else if (formRef.current?.values.rate === 5) {
      formRef.current?.setFieldValue("badge", "");
    }
  };

  const renderBadges = (bagdes) => (
    <Row gutter={[spacings.xs]}>
      {bagdes.map((badge) => {
        const isChecked = formRef.current?.values.badge === badge.key;
        return (
          <Col size={{ xs: 6, md: 3 }} key={badge.key}>
            <StyledSelectableWrapper>
              <Selectable.Medium
                name="badge"
                type="radio"
                value={badge.key}
                key={badge.key}
                className="w-100"
                onClick={() => {
                  if (!isChecked) {
                    formRef.current?.setFieldValue("badge", badge.key);
                  } else {
                    formRef.current?.setFieldValue("badge", "");
                  }
                }}
                checked={isChecked}
              >
                <label htmlFor="badge">
                  <img
                    src={badge.image}
                    alt={badge.value}
                    width={64}
                    height={64}
                  />
                  <Body14
                    strong
                    color={isChecked ? colors.primary : colors.body}
                    css={`
                      white-space: pre-wrap;
                      margin-top: ${spacings.s};
                    `}
                  >
                    {badge.value}
                  </Body14>
                </label>
              </Selectable.Medium>
            </StyledSelectableWrapper>
          </Col>
        );
      })}
    </Row>
  );

  const renderTags = (elems, name) => (
    <Row justifyContent="center">
      <Col size={{ lg: 8 }}>
        <Block display="inline-flex" flexWrap="wrap" justifyContent="center">
          {elems.map((elem, index) => {
            const currValue = formRef.current?.values[name];
            const isChecked = currValue?.includes(elem.key);
            return (
              <Block
                marginX={spacings.xs}
                marginBottom={spacings.s}
                key={`tags-rating-${index}`}
              >
                <Selectable.Small
                  shape={SELECTABLE.SHAPE.CIRCLE}
                  kind="light"
                  key={elem.key}
                  name={name}
                  type="checkbox"
                  checked={isChecked}
                  onClick={() =>
                    !isChecked
                      ? formRef.current?.setFieldValue(name, [
                          ...currValue,
                          elem.key,
                        ])
                      : formRef.current?.setFieldValue(
                          name,
                          currValue.filter((v) => v !== elem.key)
                        )
                  }
                  title={elem.value}
                />
              </Block>
            );
          })}
        </Block>
      </Col>
    </Row>
  );

  const getContentByRate = (rate) => {
    if (rate >= 5) {
      return renderBadges(badges_list);
    }
    if (rate >= 4) {
      return renderTags(improvments_list, "improvments");
    }
    return renderTags(complaints_list, "complaints");
  };

  const handleSubmit = (values) => {
    if (isEdit) {
      editRateJobber.mutate({ id: offer_id, ...values });
    } else {
      rateJobber.mutate({ id: offer_id, ...values });
    }
  };

  useEffect(() => {
    if (onSuccess && (editRateJobber.isSuccess || rateJobber.isSuccess)) {
      onSuccess(editRateJobber.isSuccess || rateJobber.isSuccess);
    }
  }, [editRateJobber.isSuccess, rateJobber.isSuccess]);

  return (
    <Block>
      {breakpoints.get({
        xs: (
          <H4 align="center">
            {polyglot.t("rating.title", {
              first_name: jobber.first_name,
            })}
          </H4>
        ),
        md: (
          <H3 align="center">
            {polyglot.t("rating.title", {
              first_name: jobber.first_name,
            })}
          </H3>
        ),
      })}
      <Formik
        initialValues={{
          rate: rate?.rate || null,
          badge: rate?.badge?.key || "",
          improvments: rate?.improvments || [],
          complaints: rate?.complaints || [],
          bad_skills: rate?.bad_skills || "",
          private_note: rate?.private_note || "",
          comment: rate?.comment || "",
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount
        innerRef={formRef}
      >
        {({ values, isValid, submitForm }) => (
          <Form
            css={`
              width: 100%;
            `}
          >
            <Block
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginY={spacings.m}
            >
              {new Array(5).fill("").map((el, index) => (
                <StyledRatingButton
                  kind={BUTTON.KIND.MINIMAL}
                  shape={BUTTON.SHAPE.CIRCLE}
                  key={`star-rating-button-${index}`}
                  onClick={() => handleRate(index + 1)}
                  rate={values.rate}
                  active={values.rate > index}
                >
                  <Icon.XLarge name="star-solid" size={sizes.size36} />
                </StyledRatingButton>
              ))}
            </Block>
            <Block marginBottom={spacings.m}>
              <Body16
                color={colors.muted}
                align="center"
                css={`
                  white-space: "pre-wrap";
                `}
              >
                {getRatingTaglineByRate(values.rate, jobber)}
              </Body16>
            </Block>
            <AnimatePresence initial={false}>
              {values.rate > 0 && (
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: "auto" }}
                  exit={{ height: 0 }}
                  transition={{ duration: 0.2 }}
                  style={{ overflow: "hidden" }}
                >
                  <Block marginY={spacings.m}>
                    {getContentByRate(values.rate)}
                  </Block>
                  <FormControl>
                    <Field name="comment">
                      {({ field }) => (
                        <Textarea
                          rows={2}
                          maxLength={800}
                          // placeholder={polyglot.t(
                          //   "rating.comment.placeholder",
                          //   {
                          //     first_name: jobber.first_name,
                          //   }
                          // )}
                          placeholder={`${polyglot.t("rating.comment.label", {
                            first_name: jobber.first_name,
                          })}...`}
                          {...field}
                        />
                      )}
                    </Field>
                  </FormControl>
                  <Divider.Cell
                    css={`
                      margin-top: ${spacings.ml};
                    `}
                  />
                  <Block marginTop={spacings.ml} marginBottom={spacings.ml}>
                    <H5
                      css={`
                        margin-bottom: ${spacings.s};
                      `}
                    >
                      {polyglot.t("rating.private_note.title")}
                    </H5>
                    <Body14
                      color={colors.muted}
                      css={`
                        margin-bottom: ${spacings.s};
                      `}
                    >
                      {polyglot.t("rating.private_note.hint", {
                        first_name: jobber.first_name,
                      })}
                    </Body14>
                    {!(
                      privateNoteIsVisible || values.private_note.length > 0
                    ) && (
                      <Button.XSmall
                        shape={BUTTON.SHAPE.PILL}
                        kind="secondary"
                        LeftComponent={() => <Icon.Medium name="eye-slashed" />}
                        onClick={() => setPrivateNoteIsVisible(true)}
                      >
                        {polyglot.t("rating.private_note.cta")}
                      </Button.XSmall>
                    )}
                    {(privateNoteIsVisible ||
                      values.private_note.length > 0) && (
                      <Block marginTop={spacings.m}>
                        <Field name="private_note">
                          {({ field }) => (
                            <Textarea
                              label={polyglot.t("rating.private_note.label")}
                              placeholder={polyglot.t(
                                "common.placeholder.textarea"
                              )}
                              {...field}
                            />
                          )}
                        </Field>
                      </Block>
                    )}
                  </Block>
                  <Row justifyContent="center">
                    <Col size={12}>
                      <Button.Large
                        onClick={submitForm}
                        block
                        disabled={!isValid}
                        isLoading={
                          rateJobber.isLoading ||
                          rateJobber.isSuccess ||
                          editRateJobber.isLoading ||
                          editRateJobber.isSuccess
                        }
                      >
                        {isEdit
                          ? polyglot.t("rating.edit_submit")
                          : polyglot.t("rating.submit")}
                      </Button.Large>
                    </Col>
                  </Row>
                </motion.div>
              )}
            </AnimatePresence>
          </Form>
        )}
      </Formik>
    </Block>
  );
};

export default JobberRating;
