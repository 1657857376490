import BaseApp from "../modules/BaseApp";
import ExtraRatingModal from "../pages/ExtraRatingModal";

const ExtraRatingApp = (props, rails) => () =>
  (
    <BaseApp>
      <ExtraRatingModal offer_id={props.offer_id} />
    </BaseApp>
  );

export default ExtraRatingApp;
