import React, { Suspense, useState } from "react";

const CategoriesNavigationModal = React.lazy(() =>
  import("../../pages/CategoriesNavigationModal")
);
const AskServiceContext = React.createContext(null);

const AskServiceProvider = ({ children }) => {
  const [categoryId, setCategoryId] = useState(null);
  const [urlParams, setUrlParams] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleShow = (data) => {
    if (data?.urlParams) setUrlParams(data.urlParams);
    if (data?.id) setCategoryId(data.id);
    setModalIsOpen(true);
  };
  const handleHide = () => {
    setModalIsOpen(false);
  };
  return (
    <AskServiceContext.Provider value={{ show: handleShow, hide: handleHide }}>
      {children}
      <Suspense fallback={<div />}>
        {modalIsOpen && (
          <CategoriesNavigationModal
            category_id={categoryId}
            url_params={urlParams}
            isOpen={modalIsOpen}
            onClose={handleHide}
          />
        )}
      </Suspense>
    </AskServiceContext.Provider>
  );
};

export { AskServiceContext };
export default AskServiceProvider;
