/* eslint-disable import/no-cycle */
import { FastField } from "formik";
import * as yup from "yup";
import { colors } from "../../assets/themes";
import { Block } from "../../components";
import {
  addressValidation,
  destinationAddressValidation,
  firstNameValidation,
  lastNameValidation,
  PASSWORD_MINIMAL_LENGTH,
} from "../../utils";
import polyglot from "../../utils/polyglot";
import Address from "./common/Address";
import AddressKind from "./common/AddressKind";
import Concierge from "./common/Concierge";
import {
  FormInput,
  FormSelectables,
  FormTextarea,
  StyledFormControl,
} from "./common/fields";
import Floor from "./common/Floor";
import Level from "./common/Level";
import Lift from "./common/Lift";
import CurrentPassword from "./common/login/CurrentPassword";
import FirstName from "./common/login/FirstName";
import LastName from "./common/login/LastName";
import NewPassword from "./common/login/NewPassword";
import NbHoursSelection from "./common/NbHoursSelection";
import Photos from "./common/Photos";
import Sav from "./common/Sav";
import {
  ADDRESS_PATH,
  CONCIERGE_PATH,
  CURRENT_PASSWORD_PATH,
  DESTINATION_ADDRESS_PATH,
  DESTINATION_FLOOR_PATH,
  DESTINATION_LIFT_PATH,
  DESTINATION_PROPERTY_TYPE_PATH,
  FIRST_NAME_PATH,
  FLOOR_PATH,
  LAST_NAME_PATH,
  LIFT_PATH,
  MAX_DESCRIPTION_LENGTH,
  MAX_TITLE_LENGTH,
  NB_HOURS_PATH,
  PASSWORD_PATH,
  PHOTOS_PATH,
  PROPERTY_TYPE_PATH,
  SAV_PATH,
  START_ADDRESS_PATH,
} from "./constants";

export const DEFAULT_SCHEMA = yup.object().shape({});
export const FREQUENCY_OPTIONS = [
  { label: polyglot.t("categories.frequency.one_time"), value: "one_time" },
  {
    label: polyglot.t("categories.frequency.twice_per_month"),
    value: "twice_per_month",
  },
  {
    label: polyglot.t("categories.frequency.once_per_week"),
    value: "once_per_week",
  },
  {
    label: polyglot.t("categories.frequency.multiple_times_per_week"),
    value: "multiple_times_per_week",
  },
];
export const FREQUENCY = {
  validationSchema: {
    frequency: yup.string().required(),
  },
  initialValues: { frequency: "" },
  nhConfig: {
    frequency: {
      label: polyglot.t("common.frequency"),
      options: FREQUENCY_OPTIONS,
    },
  },
  View: () => (
    <StyledFormControl label={polyglot.t("common.job_frequency")}>
      <FormSelectables isWrap options={FREQUENCY_OPTIONS} name="frequency" />
    </StyledFormControl>
  ),
};

export const CUSTOM = {
  validationSchema: {
    title: yup
      .string()
      .required()
      .test("non-empty-title", (value) => value?.replace(/\s/g, "").length > 0),
    description: yup.string().notRequired(),
  },
  initialValues: { title: "", description: "" },
  nhConfig: {
    title: { label: polyglot.t("common.title") },
    description: { label: polyglot.t("common.description") },
  },
  View: ({ children }) => (
    <>
      <FastField name="title">
        {({ field }) => (
          <StyledFormControl
            label={() => (
              <Block display="flex" justifyContent="space-between">
                <span>{polyglot.t("categories.job_title")}</span>
                <span
                  css={`
                    color: ${colors.muted};
                  `}
                >
                  {field.value.length} / {MAX_TITLE_LENGTH}
                </span>
              </Block>
            )}
          >
            <FormInput
              clearable
              name={field.name}
              maxLength={MAX_TITLE_LENGTH}
              placeholder={polyglot.t("categories.add_explicite_title")}
            />
          </StyledFormControl>
        )}
      </FastField>
      {children}
      <StyledFormControl
        label={`${polyglot.t("categories.additionnal_details")} (${polyglot.t(
          "common.not_required"
        )})`}
      >
        <FormTextarea
          name="description"
          resize
          maxLength={MAX_DESCRIPTION_LENGTH}
          placeholder={polyglot.t("common.placeholder.textarea")}
        />
      </StyledFormControl>
    </>
  ),
};

export const DEFAULT_CONFIG = {
  multipleJobbersAllowed: false,
  isDateRange: false,
  isTimeRange: false,
  withFrequency: false,
  IsNbHoursReadOnly: false,
  isCustom: false,
  noTime: false,
  forceSummaryColumn: false,
  initialTitleIsEmpty: false,
  hidePhotos: false,
  renderPages: null,
  addressDetails: false,
  hideAddressPage: false,
};

export const SAV_PAGE = {
  title: "SAV",
  path: SAV_PATH,
  validationSchema: yup.object().shape({
    sav_reason_id: yup.string().required(),
    concierge_customer_id: yup.number().required(),
  }),
  View: Sav,
};

export const CONCIERGE_PAGE = {
  title: "Client",
  path: CONCIERGE_PATH,
  validationSchema: yup.object().shape({
    concierge_customer_id: yup.number().required(),
  }),
  View: Concierge,
};

export const SIGNUP_PAGES = [
  {
    path: FIRST_NAME_PATH,
    View: FirstName,
    validationSchema: yup.object().shape({
      user: yup.object().shape({ ...firstNameValidation }),
    }),
  },
  {
    path: LAST_NAME_PATH,
    View: LastName,
    validationSchema: yup.object().shape({
      user: yup.object().shape({ ...lastNameValidation }),
    }),
  },
  {
    path: PASSWORD_PATH,
    View: NewPassword,
    validationSchema: yup.object().shape({
      user: yup.object().shape({
        password: yup
          .string()
          .min(
            PASSWORD_MINIMAL_LENGTH,
            polyglot.t("common.errors.password_too_short_plural", {
              count: PASSWORD_MINIMAL_LENGTH,
            })
          )
          .required(),
      }),
    }),
  },
];

export const APARTMENT_PAGES = [
  {
    path: FLOOR_PATH,
    title: polyglot.t("categories.apartment_floor"),
    View: Floor,
    customProps: { name: "floor" },
    validationSchema: yup.object().shape({
      floor: yup.number().required(),
    }),
  },
  {
    path: LIFT_PATH,
    title: polyglot.t("categories.has_elevator"),
    View: Lift,
    customProps: { name: "lift" },
    validationSchema: yup.object().shape({
      lift: yup.boolean().required(),
    }),
  },
];

export const HOUSE_PAGES = [
  {
    path: FLOOR_PATH,
    title: polyglot.t("categories.storage_levels"),
    View: Level,
    customProps: { name: "floor" },
    validationSchema: yup.object().shape({
      floor: yup.number().required(),
    }),
  },
];

export const STORAGE_PAGES = [
  {
    path: FLOOR_PATH,
    title: polyglot.t("categories.storage_floor"),
    View: Floor,
    customProps: { name: "floor" },
    validationSchema: yup.object().shape({
      floor: yup.number().required(),
    }),
  },
  {
    path: LIFT_PATH,
    title: polyglot.t("categories.has_elevator"),
    View: Lift,
    customProps: { name: "lift" },
    validationSchema: yup.object().shape({
      lift: yup.boolean().required(),
    }),
  },
];

export const DESTINATION_APARTMENT_PAGES = [
  {
    path: DESTINATION_FLOOR_PATH,
    title: polyglot.t("categories.apartment_floor"),
    View: Floor,
    customProps: { name: "destination_floor" },
    validationSchema: yup.object().shape({
      destination_floor: yup.number().required(),
    }),
  },
  {
    path: DESTINATION_LIFT_PATH,
    title: polyglot.t("categories.has_elevator"),
    View: Lift,
    customProps: { name: "destination_lift" },
    validationSchema: yup.object().shape({
      destination_lift: yup.boolean().required(),
    }),
  },
];

export const DESTINATION_HOUSE_PAGES = [
  {
    path: DESTINATION_FLOOR_PATH,
    title: polyglot.t("categories.number_of_levels"),
    View: Level,
    customProps: { name: "destination_floor" },
    validationSchema: yup.object().shape({
      destination_floor: yup.number().required(),
    }),
  },
];

export const DESTINATION_STORAGE_PAGES = [
  {
    path: DESTINATION_FLOOR_PATH,
    title: polyglot.t("categories.storage_floor"),
    View: Floor,
    customProps: { name: "destination_floor" },
    validationSchema: yup.object().shape({
      destination_floor: yup.number().required(),
    }),
  },
  {
    path: DESTINATION_LIFT_PATH,
    title: polyglot.t("categories.has_elevator"),
    View: Lift,
    customProps: { name: "destination_lift" },
    validationSchema: yup.object().shape({
      destination_lift: yup.boolean().required(),
    }),
  },
];

export const LOGIN_PAGES = [
  {
    path: CURRENT_PASSWORD_PATH,
    View: CurrentPassword,
    validationSchema: yup.object().shape({
      user: yup.object().shape({
        password: yup.string().required(),
      }),
    }),
  },
];

export const ADDRESS_DETAILS_PAGES = [
  {
    path: START_ADDRESS_PATH,
    View: Address,
    title: polyglot.t("categories.departure_address"),
    validationSchema: yup.object().shape(
      {
        ...addressValidation,
      },
      [
        ["place_id", "address_id"],
        ["address_name", "city_id"],
      ]
    ),
  },
  {
    path: PROPERTY_TYPE_PATH,
    View: AddressKind,
    title: polyglot.t("categories.move_out_property_type"),
    validationSchema: yup.object().shape({
      property_type: yup.string().required(),
    }),
  },
  {
    path: DESTINATION_ADDRESS_PATH,
    View: Address,
    customProps: { name_prefix: "destination_" },
    title: polyglot.t("categories.arrival_address"),
    validationSchema: yup.object().shape(
      {
        ...destinationAddressValidation,
      },
      [
        ["destination_place_id", "destination_address_id"],
        ["destination_address_name", "destination_city_id"],
      ]
    ),
  },
  {
    path: DESTINATION_PROPERTY_TYPE_PATH,
    View: AddressKind,
    customProps: { name_prefix: "destination_" },
    title: polyglot.t("categories.move_in_property_type"),
    validationSchema: yup.object().shape({
      destination_property_type: yup.string().required(),
    }),
  },
];

export const ADDRESS_PAGE = {
  path: ADDRESS_PATH,
  View: Address,
  title: polyglot.t("categories.what_is_job_address"),
  validationSchema: yup.object().shape(
    {
      ...addressValidation,
    },
    [
      ["place_id", "address_id"],
      ["address_name", "city_id"],
    ]
  ),
};

export const PHOTOS_PAGE = {
  path: PHOTOS_PATH,
  View: Photos,
  title: `${polyglot.t("categories.do_you_want_to_add_photos")} (${polyglot.t(
    "common.not_required"
  )})`,
  validationSchema: DEFAULT_SCHEMA,
};

export const NB_HOURS_SELECTION_PAGE = {
  path: NB_HOURS_PATH,
  title: polyglot.t("categories.how_many_hours_needed"),
  View: NbHoursSelection,
  validationSchema: yup.object().shape({
    nb_hours: yup.number().required(),
  }),
};
