import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useCategoryForm } from "../../modules/hooks";
import { PRIVATE_JOBBER_PATH } from "./constants";
import useCategoryValue from "./useCategoryValue";
import { getCategoryFormQueryId } from "./utils";

export function useAutoUpdateCategoryValues() {
  const {
    values,
    setFieldValue,
    dirty,
    resetForm,
    initialValues,
    validateForm,
  } = useFormikContext();
  const { getNbHours, categoryNhHours, getPricePerHour, getNbJobbers } =
    useCategoryValue();
  const { id, _propsInitialValues, defaultPages, pages, setPages } =
    useCategoryForm();
  const queryClient = useQueryClient();
  const [defaultPrivateJobberPage] = useState(
    defaultPages.find((p) => p.path === PRIVATE_JOBBER_PATH)
  );

  const setNewAddressInExistingAddresses = (value) => {
    const place_id = value.place_id || value.destination_place_id;
    const city_id = value.city_id || value.destination_city_id;
    const address = value.address || value.destination_address;
    const address_name = value.address_name || value.destination_address_name;
    const address_id = value.address_id || value.destination_address_id;
    const location =
      value.address_location || value.destination_address_location;

    queryClient.setQueryData([getCategoryFormQueryId({ id })], (old) => {
      const isKnownAddress = old.addresses.find(
        (address) =>
          (address.id && address.id === address_id) ||
          (address.place_id && address.place_id === place_id) ||
          (address.city_id &&
            address.address_name &&
            address.city_id === city_id &&
            address.address_name === address_name)
      );

      return {
        ...old,
        addresses: [
          ...(!isKnownAddress
            ? {
                place_id,
                city_id,
                address,
                location,
                address_name,
              }
            : []),
          ...old.addresses,
        ],
      };
    });
  };

  useEffect(() => {
    if (values.address) setNewAddressInExistingAddresses(values);
  }, [values.address]);

  useEffect(() => {
    if (dirty) {
      // prevent trigger if no field has changed
      if (!_propsInitialValues.nb_hours) {
        // prevent nb_hours change if nb_hours injected in props
        setFieldValue("nb_hours", getNbHours(), false);
      }
    }
    setFieldValue("nb_hours_calculated", getNbHours(), false);
  }, [categoryNhHours]);

  useEffect(() => {
    if (dirty) {
      setFieldValue("nb_jobbers", getNbJobbers());
    }
  }, [categoryNhHours]);

  useEffect(() => {
    // remove private-jobber page if nb_jobbers > 1
    if (defaultPrivateJobberPage) {
      const privateJobberPageIndex = pages.findIndex(
        (p) => p.path === PRIVATE_JOBBER_PATH
      );

      if (values.nb_jobbers > 1) {
        setPages((pages) =>
          pages.filter((p) => p.path !== PRIVATE_JOBBER_PATH)
        );
        // if page has been removed
      } else if (privateJobberPageIndex < 0) {
        const newPages = [...pages];
        newPages.splice(privateJobberPageIndex, 0, defaultPrivateJobberPage);
        setPages(newPages);
      }
    }
  }, [values.nb_jobbers]);

  useEffect(() => {
    if (dirty) {
      // prevent trigger if no field has changed
      setFieldValue("price_per_hour", getPricePerHour(), false);
    }
  }, [categoryNhHours, values.date]);

  useEffect(() => {
    // force auto validation on values change (patch formik bug)
    validateForm();
  }, [values]);

  useEffect(() => {
    // setup default nb_hours and price_per_hour
    resetForm({
      values: {
        ...values,
        nb_hours: initialValues.nb_hours || getNbHours(),
        nb_hours_calculated: initialValues.nb_hours_calculated || getNbHours(),
        price_per_hour: getPricePerHour(),
      },
    });
  }, []);
}
