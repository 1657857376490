import { useState } from "react";
import styled from "styled-components";
import polyglot from "@/utils/polyglot";
import Button from "./Button";

const StyledText = styled.p`
  margin-bottom: 0;
  word-break: break-word;
  white-space: break-spaces;
`;

const ReadMore = ({ children, max = 150, className }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const textIsTooLarge = children.split("").length > max;
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <StyledText className={className}>
      {textIsTooLarge ? (
        <>
          {isReadMore ? `${children.slice(0, max)}... ` : `${children} `}
          <Button.Link
            onClick={toggleReadMore}
            css={`
              display: inline;
              padding-left: 0;
              padding-right: 0;
              padding-top: 0;
              padding-bottom: 0;
              min-height: 0;
            `}
          >
            {isReadMore
              ? polyglot.t("common.read_more")
              : polyglot.t("common.read_less")}
          </Button.Link>
        </>
      ) : (
        children
      )}
    </StyledText>
  );
};

export default ReadMore;
