import { css } from "styled-components";
import { BREAKPOINTS } from "../../utils/constants";

export const numberToPx = (v) => (typeof v === "string" ? v : `${v}px`);

export const getCssProperty = (val, element = document.body) =>
  getComputedStyle(element).getPropertyValue(
    val.split("(")[1].replace(")", "")
  );

export const cssVarToNumber = (val, unit = "px", element = document.body) =>
  Number(
    getComputedStyle(element)
      .getPropertyValue(val.split("(")[1].replace(")", ""))
      .replace(unit, "")
  );

export const breakpointsEnhancer = (cssProp, values, regex) => {
  let prefix = "";
  let suffix = "";

  if (regex) {
    const match = regex.match(/^(.*?)%value(.*)$/);
    if (match) {
      [, prefix, suffix] = regex.match(/^(.*?)%value(.*)$/) || [];
    }
  }
  if (
    (typeof values === "object" ||
      typeof values === "string" ||
      typeof values === "number") &&
    values !== null
  ) {
    if (typeof values !== "object") {
      return css`
        ${cssProp}: ${prefix}${numberToPx(values)}${suffix}
      `;
    }
    const breakpointProps = Object.keys(values).reduce(
      (mediaQueries, value) => {
        const [screenBreakpoint, cssPropBreakpoint] = [value, values[value]];
        let result = mediaQueries;
        result += `
        @media screen and (min-width: ${BREAKPOINTS[screenBreakpoint]}px) {
          ${cssProp}: ${prefix}${cssPropBreakpoint}${suffix};
        }
        `;
        return result;
      },
      ""
    );
    return css([breakpointProps]);
  }
  return null;
};
