import { useFormikContext } from "formik";
import { colors, spacings } from "../../../assets/themes";
import {
  Block,
  Body16,
  Body18,
  Icon,
  List,
  Radio,
  Shape,
} from "../../../components";
import { SHAPE } from "../../../components/Styles/variants";
import { useBreakpoints, useCategoryForm } from "../../../modules/hooks";
import polyglot from "../../../utils/polyglot";
import JobberOfferItem from "../../dashboard/jobs/JobberOfferItem";
import StepTitle from "./StepTitle";

const PrivateJobberStep = ({ title }) => {
  const breakpoints = useBreakpoints();
  const { jobbers } = useCategoryForm();
  const { setFieldValue, values } = useFormikContext();
  const name = "private_jobber_id";

  const handleChange = (id) => {
    if (id) {
      setFieldValue(name, id);
    } else {
      setFieldValue(name, null);
    }
  };

  return (
    <>
      <StepTitle>{title}</StepTitle>
      <Block marginTop={spacings.m}>
        <List.Item
          onClick={() => handleChange(null)}
          RightComponent={() => <Radio checked={!values[name]} />}
          LeftComponent={() => (
            <Shape.Medium
              shape={SHAPE.SHAPE.CIRCLE}
              backgroundColor={colors.mutedLight}
            >
              <Icon.XLarge name="user" color={colors.onColor} />
            </Shape.Medium>
          )}
        >
          {breakpoints.get({
            xs: (
              <Body16 strong>
                {polyglot.t("categories.no_its_for_everybody")}
              </Body16>
            ),
            md: (
              <Body18 strong>
                {polyglot.t("categories.no_its_for_everybody")}
              </Body18>
            ),
          })}
        </List.Item>
      </Block>
      <List.Header>Vos prestataires ({jobbers.length})</List.Header>
      {jobbers.map((jobber) => (
        <JobberOfferItem
          hideDetails
          divider
          withGutters={false}
          RightComponent={() => <Radio checked={jobber.id === values[name]} />}
          onClick={() => handleChange(jobber.id)}
          jobber={jobber}
          key={`private-jobber-selection-${jobber.id}`}
        />
      ))}
    </>
  );
};

export default PrivateJobberStep;
