import React from "react";
import { colors, sizes, spacings } from "../../assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Body16,
  Col,
  Divider,
  Link,
  Rating,
  Row,
  Tag,
} from "../../components";
import { TAG } from "../../components/Styles/variants";

const UserComment = ({
  avatar,
  firstName,
  url,
  body,
  createdAt,
  jobTitle,
  jobUrl,
  rate,
  badge,
}) => (
  <Block paddingTop={spacings.l} marginTop={spacings.m}>
    <Row alignItems="stretch" gutter={spacings.s}>
      <Col size={{ xs: 12, sm: 3, lg: 2 }}>
        <Block marginBottom={{ xs: spacings.sm, sm: "0px" }}>
          <Block
            display="flex"
            flexDirection={{ xs: "row", sm: "column" }}
            alignItems="center"
            paddingRight={spacings.s}
          >
            <Block marginBottom={{ xs: 0, sm: spacings.xs }}>
              <Link href={url}>
                <Avatar
                  src={avatar}
                  alt={firstName}
                  size={sizes.size48}
                  name={firstName}
                />
              </Link>
            </Block>
            <Block
              display="flex"
              flexDirection="column"
              alignItems={{ xs: "start", sm: "center" }}
              marginLeft={{ xs: spacings.s, sm: "0px" }}
            >
              <Link
                href={url}
                css={`
                  word-break: break-word;
                  white-space: break-spaces;
                `}
                color={colors.body}
              >
                <Body16 align={{ xs: "left", sm: "center" }}>
                  {firstName}
                </Body16>
              </Link>
              <Body14 align={{ xs: "left", sm: "center" }} color={colors.muted}>
                {createdAt}
              </Body14>
            </Block>
          </Block>
        </Block>
      </Col>
      <Col size={{ xs: 12, sm: 9, lg: 10 }}>
        <Block>
          <Link href={jobUrl} color={colors.body}>
            <Body16>{jobTitle}</Body16>
          </Link>
          <Rating rate={rate} />
          <Body16
            css={`
              margin-top: ${spacings.xs};
              word-wrap: break-word;
            `}
          >
            {body}
          </Body16>
          {badge && (
            <Tag.Small
              css={`
                display: inline-flex;
                padding-left: 1px;
                margin-top: ${spacings.s};
              `}
              kind={TAG.KIND.WHITE}
              LeftComponent={() => (
                <Avatar src={badge.image} alt="" size={sizes.size20} />
              )}
            >
              {badge.value}
            </Tag.Small>
          )}
        </Block>
      </Col>
    </Row>
  </Block>
);

export default UserComment;
