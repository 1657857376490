import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useCategoryForm } from "../../modules/hooks";
import { useAutoUpdateCategoryValues } from "./useAutoUpdateCategoryValues";
import { useCategoryFormLocalStorage } from "./useCategoryFormLocalStorage";
import useCategoryFormUrlParams from "./useCategoryFormUrlParams";

const CategoryFormHooks = () => {
  const { values } = useFormikContext();
  const { cookieEnabled, id } = useCategoryForm();
  useAutoUpdateCategoryValues();
  useCategoryFormUrlParams();
  const categoryFormLocalStorage = useCategoryFormLocalStorage(id);

  useEffect(() => {
    if (cookieEnabled)
      window.addEventListener("beforeunload", () =>
        categoryFormLocalStorage.set(values)
      );
    return () => {
      if (cookieEnabled)
        window.removeEventListener("beforeunload", () =>
          categoryFormLocalStorage.set(values)
        );
    };
  }, [values]);

  return <></>;
};
export default CategoryFormHooks;
