import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import useToast from "./useToast";
import { api } from "../../utils";
import polyglot from "../../utils/polyglot";

export default function useFetch(query, options = {}) {
  const toast = useToast();
  const res = useQuery(
    [options.queryKey || query],
    async () => {
      const { data } = await api.get(query, { params: options.params });
      if (options.apiBackwardCompatibility) {
        return data;
      }
      return data.data;
    },
    options
  );
  useEffect(() => {
    if (res.isError && !options?.shadow)
      toast.danger(polyglot.t("common.errors.get"));
  }, [res.isError]);

  return { ...res, isLoading: res.isLoading || !res.data, key: query };
}
