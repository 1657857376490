import React from "react";
import BaseApp from "../modules/BaseApp";
import JobberProfileRating from "../pages/JobberProfileRating";

const JobberProfileRatingApp = (props, rails) => () =>
  (
    <BaseApp>
      <JobberProfileRating {...props} />
    </BaseApp>
  );

export default JobberProfileRatingApp;
