import styled from "styled-components";
import {
  borderWidth,
  colors,
  radius,
  sizes,
  spacings,
} from "../../assets/themes";
import Block from "../Block";
import Icon from "../Icon";
import { ButtonBase } from "../Styles/Base";

const StyledChildren = styled.div`
  border-radius: var(--wrapper-radius);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledBgImg = styled.div`
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  width: 100%;
  height: 100%;
`;

const StyledImgWrapper = styled.div`
  --wrapper-radius: ${radius.m};
  border: solid ${borderWidth.m} ${colors.border};
  border-radius: var(--wrapper-radius);
  border-color: ${colors.border};
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: calc(var(--wrapper-radius) - 2px);
    background-color: transparent;
  }
`;

const StyledIconWrapper = styled.div`
  width: ${sizes.size24};
  height: ${sizes.size24};
  display: none;
  background: ${colors.background};
  border-radius: ${sizes.size24};
  position: absolute;
  top: -8px;
  right: -8px;
  border: solid ${borderWidth.m} ${colors.background};
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLabel = styled.label`
  width: 100%;
  display: block;
  cursor: pointer;
`;

const StyledWrapper = styled(ButtonBase)`
  width: 100%;
  position: relative;
  border-radius: ${radius.m};
  display: inline-flex;
  &[aria-checked="true"] ${StyledLabel} {
    ${StyledIconWrapper} {
      display: block;
    }
    ${StyledImgWrapper} {
      border-color: ${colors.primary};
    }
  }
  &:hover ${StyledLabel} {
    ${StyledImgWrapper} {
      &:after {
        background-color: ${colors.overlayDark};
      }
    }
  }
  &:active ${StyledLabel} {
    ${StyledImgWrapper} {
      &:before {
        background-color: ${colors.overlayDark};
      }
    }
  }
`;

const CardSelectable = ({
  checked = false,
  value,
  name = "selectables",
  type = "button",
  role = "checkbox",
  onClick = () => {},
  image,
  children,
  className,
  id = `card-selectable${Math.random()}`,
}) => (
  <StyledWrapper
    className={className}
    id={id}
    type={type}
    role={role}
    onClick={onClick}
    value={value}
    name={name}
    aria-checked={checked.toString()}
  >
    <StyledLabel htmlFor={id}>
      <StyledImgWrapper>
        <StyledChildren>
          {typeof image === "function" ? image() : <StyledBgImg src={image} />}
        </StyledChildren>
        {checked && (
          <StyledIconWrapper>
            <Icon.XLarge name="check-circle-solid" color={colors.primary} />
          </StyledIconWrapper>
        )}
      </StyledImgWrapper>

      <Block marginTop={spacings.s} color={colors.body}>
        {children}
      </Block>
    </StyledLabel>
  </StyledWrapper>
);
export default CardSelectable;
