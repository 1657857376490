import { useFormikContext } from "formik";
import { spacings } from "../../assets/themes";
import { Block, Modal } from "../../components";
import ExtraRatingInput from "./ExtraRatingInput";

const ExtraRatingPage = ({ title, fields, goBack, onClose, index }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Block>
      <Modal.Item.Header onClose={onClose} onBack={goBack}>
        <Modal.Item.Title>{title}</Modal.Item.Title>
      </Modal.Item.Header>
      <Modal.Item.Wrapper>
        <Block display="flex" flexDirection="column" gap={spacings.sm}>
          {fields.map((field) => (
            <ExtraRatingInput
              key={`${field.value}-extra-rate-flow-${index}`}
              onChange={() => {
                setFieldValue(field.name, field.value);
              }}
              checked={values[field.name] === field.value}
              name={field.name}
              title={field.title}
              subtitle={field.subtitle}
            />
          ))}
        </Block>
      </Modal.Item.Wrapper>
    </Block>
  );
};
export default ExtraRatingPage;
