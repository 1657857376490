import { useRef } from "react";
import Divider from "./Divider";
import { spacings } from "../assets/themes";
import polyglot from "../utils/polyglot";
import AutoSizeTextarea from "./AutoSizeTextarea";
import Block from "./Block";
import Button from "./Button";
import Icon from "./Icon";
import { BUTTON, INPUT } from "./Styles/variants";

const ChatInput = ({
  value,
  isLoading,
  LeftComponent,
  children,
  content,
  name,
  disabled,
  maxLength,
  placeholder = polyglot.t("chat.add_comment"),
  onChange = () => {},
}) => {
  const inputRef = useRef();
  return (
    <>
      <Divider.Cell />
      <Block display="flex" flexDirection="column" paddingY={spacings.sm}>
        {!disabled && (
          <Block
            display="flex"
            paddingRight={spacings.m}
            paddingLeft={!LeftComponent && spacings.m}
          >
            {LeftComponent && (
              <div
                css={`
                  margin-right: ${spacings.xs};
                  display: flex;
                  align-items: center;
                `}
              >
                {LeftComponent()}
              </div>
            )}
            <Block flex="1">
              <AutoSizeTextarea
                name={name}
                ref={inputRef}
                value={value}
                placeholder={placeholder}
                shape={INPUT.SHAPE.CIRCLE}
                onChange={onChange}
                maxLength={maxLength}
                RightComponent={() => (
                  <Button.Small
                    type="submit"
                    disabled={
                      inputRef.current
                        ? inputRef?.current?.value?.trim() === ""
                        : true
                    }
                    isLoading={isLoading}
                    shape={BUTTON.SHAPE.CIRCLE}
                  >
                    <Icon.Large name="arrow-up" />
                  </Button.Small>
                )}
              >
                {!!content && content()}
              </AutoSizeTextarea>
            </Block>
          </Block>
        )}
        {children && (
          <Block
            css={`
              margin-top: ${spacings.xs};
              margin-left: ${spacings.sm};
              margin-right: ${spacings.sm};
            `}
          >
            {children}
          </Block>
        )}
      </Block>
    </>
  );
};
export default ChatInput;
