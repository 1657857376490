import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Block, HistoryScrollToTop } from "../components";
import { DashboardContext } from "../modules/contexts";
import { Menu } from "./dashboard/";
import DashboardRoutes from "./dashboard/DashboardRoutes";
import BottomNav from "./dashboard/BottomNav";
import { useWindowSize } from "../modules/hooks";
import { BREAKPOINTS } from "../utils";
import { sizes } from "../assets/themes";

const BOTTOM_NAV_HEIGHT = `calc(${sizes.size64} + env(safe-area-inset-bottom))`;

const Dashboard = () => {
  const badges = useSelector((state) => state.badges);
  const { current_user } = useSelector((state) => state.rails);
  const { public_pages } = useContext(DashboardContext);
  const { width } = useWindowSize();

  return (
    <Block
      minHeight="100%"
      display="flex"
      alignItems="stretch"
      marginBottom={{ xs: BOTTOM_NAV_HEIGHT, md: 0 }}
    >
      <HistoryScrollToTop />
      <Block
        width="300px"
        display={{ xs: "none", md: "flex" }}
        position="fixed"
        height="100%"
        zIndex="9"
        top="var(--nav-height)"
      >
        <Menu public_pages={public_pages} data={badges} />
      </Block>
      {width <= BREAKPOINTS.md && (
        <BottomNav data={badges} user={current_user} />
      )}
      <DashboardRoutes data={badges} />
    </Block>
  );
};

export default Dashboard;
