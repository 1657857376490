import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Block } from "../../components";
import { DashboardContext } from "../../modules/contexts";
import polyglot from "../../utils/polyglot";
import ExtraRatingModal from "../ExtraRatingModal";
import DeleteAccount from "./account/DeleteAccount";
import TransactionDetailsModal from "./account/TransactionDetailsModal";
import { GET_ACCOUNT_MENU } from "./config/account-menu";
import {
  GET_DASHBOARD_ALT_MENU,
  GET_DASHBOARD_MAIN_MENU,
  GET_REFFERAL_MENU_ITEM,
} from "./config/main-menu";
import {
  ACCOUNT_PATH,
  ACCOUNT_TAXES_PAY_PATH,
  ACCOUNT_WALLET_PATH,
  DASHBOARD_EXTRA_RATING_PATH,
  DELETE_ACCOUNT_PATH,
  HOME_PATH,
  NOTIFICATIONS_PATH,
} from "./config/routes";
import { Layouts, Notifications } from "./index";
import JobberTaxesModal from "./taxesCredit/JobberTaxesModal";

const DashboardRoutes = ({ data }) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const accountData = queryClient.getQueryData([ACCOUNT_PATH]);
  const user = accountData?.user;
  const { public_pages } = useContext(DashboardContext);
  const background = location.state && location.state.background;
  const renderAccountMenuRoutes = useCallback(
    () =>
      GET_ACCOUNT_MENU({ data, user, public_pages }).map((item) =>
        item.routes.map(
          (route) =>
            route.path &&
            !route.hidden && (
              <Route path={route.path} key={`account-route-${route.path}`}>
                <Layouts.Content title={route.title}>
                  <route.View
                    config={route}
                    key={`route-account-${route.path}`}
                  />
                </Layouts.Content>
              </Route>
            )
        )
      ),
    [JSON.stringify(data), JSON.stringify(user)]
  );
  const renderMenuRoutes = useCallback(
    () =>
      [
        ...GET_DASHBOARD_MAIN_MENU({ data }),
        ...GET_REFFERAL_MENU_ITEM({}),
        ...GET_DASHBOARD_ALT_MENU({ public_pages, data }),
      ].map(
        (item) =>
          item.path && (
            <Route
              exact={item.exact}
              key={`route-id-${item.path}`}
              path={item.path}
            >
              <item.Layout title={item.title}>
                <item.View config={item} public_pages={public_pages} />
              </item.Layout>
            </Route>
          )
      ),
    [JSON.stringify(data)]
  );
  return (
    <Block
      width={{ xs: "100%", md: "calc(100% - 300px)" }}
      marginLeft={{ xs: 0, md: "300px" }}
    >
      <Switch location={background || location}>
        {renderMenuRoutes()}
        {renderAccountMenuRoutes()}
        <Route exact path={NOTIFICATIONS_PATH}>
          <Layouts.Content title={polyglot.t("routes.notifications")}>
            <Notifications
              config={{ title: polyglot.t("routes.notifications") }}
            />
          </Layouts.Content>
        </Route>
        <Route exact path={DELETE_ACCOUNT_PATH}>
          <Layouts.Content title={polyglot.t("routes.delete_account")}>
            <DeleteAccount />
          </Layouts.Content>
        </Route>
        <Redirect from="*" to={HOME_PATH} />
      </Switch>
      <Route
        path={[
          `${ACCOUNT_WALLET_PATH}/:id/details_offer`,
          `${ACCOUNT_WALLET_PATH}/:id/details_direct_payment`,
          `${ACCOUNT_WALLET_PATH}/:id/details_subscription_payment`,
          `${ACCOUNT_WALLET_PATH}/:id/details_instant_payment`,
        ]}
      >
        <TransactionDetailsModal />
      </Route>
      <Route path={ACCOUNT_TAXES_PAY_PATH}>
        <JobberTaxesModal />
      </Route>
      <Route path={DASHBOARD_EXTRA_RATING_PATH}>
        <ExtraRatingModal isOpen onClose={() => null} />
      </Route>
    </Block>
  );
};

export default DashboardRoutes;
