import { colors, radius, spacings } from "@/assets/themes";
import capitalize from "@/utils/capitalize";
import polyglot from "@/utils/polyglot";
import Block from "../Block";
import { Col, Row } from "../Grid";
import List from "../List";
import { Body14, Body16 } from "../Text";
import SkillItem from "./SkillItem";

const OfferBabySitting = ({
  license_number,
  toddlers,
  preschoolers,
  school_age_children,
  preteens,
  at_caregiver_home,
  at_parents_home,
  age,
  baby_sitter_kind,
  job_distance,
  city,
  is_smoker,
  has_driving_license,
}) => {
  const getAdditionnalInfos = () => {
    const arr = [];
    if (!is_smoker) arr.push(polyglot.t("common.non_smoker"));
    if (has_driving_license) arr.push(polyglot.t("common.driver_licence"));
    return capitalize(arr.join(", ").toLowerCase());
  };

  const getCarePlaces = () => {
    const places = [];
    if (at_caregiver_home)
      places.push(polyglot.t("babysitting.at_jobber_home"));
    if (at_parents_home) places.push(polyglot.t("babysitting.at_parents_home"));
    return capitalize(places.join(", ").toLowerCase());
  };

  const getChildsAges = () => {
    const ages = [];
    if (toddlers) ages.push(polyglot.t("babysitting.child_toddlers"));
    if (preschoolers) ages.push(polyglot.t("babysitting.child_preschoolers"));
    if (school_age_children)
      ages.push(polyglot.t("babysitting.child_school_age_children"));
    if (preteens) ages.push(polyglot.t("babysitting.child_preteens"));
    return capitalize(ages.join(", ").toLowerCase());
  };
  return (
    <div>
      <Block marginBottom={spacings.s}>
        <Row gutter={[spacings.s]} alignItems="stretch">
          <Col size={6}>
            <Block
              backgroundColor={colors.backgroundLight}
              paddingY={spacings.xs}
              borderRadius={radius.ml}
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
            >
              <SkillItem strong iconName="user" divider={false}>
                {polyglot.t("common.years_old_plural", { count: age })}
              </SkillItem>
            </Block>
          </Col>
          <Col size={6}>
            <Block
              backgroundColor={colors.backgroundLight}
              paddingY={spacings.xs}
              borderRadius={radius.ml}
              overflow="hidden"
              display="flex"
              alignItems="center"
            >
              <SkillItem iconName="map-marker" divider={false}>
                <Body16 strong>{job_distance} km</Body16>
                <Body14 numberOfLines={1} color={colors.muted}>
                  {city}
                </Body14>
              </SkillItem>
            </Block>
          </Col>
        </Row>
      </Block>
      {(baby_sitter_kind ||
        getChildsAges()?.length > 0 ||
        getCarePlaces()?.length > 0 ||
        getAdditionnalInfos()?.length > 0) && (
        <Block
          backgroundColor={colors.backgroundLight}
          paddingY={spacings.xs}
          borderRadius={radius.ml}
          overflow="hidden"
        >
          <List.Group>
            {baby_sitter_kind && (
              <SkillItem iconName="work">
                <Body16>{polyglot.t(`babysitting.${baby_sitter_kind}`)}</Body16>
                {license_number && (
                  <Body14 color={colors.muted}>
                    {polyglot.t("babysitting.agrement_number")} :{" "}
                    {license_number}
                  </Body14>
                )}
              </SkillItem>
            )}
            {getChildsAges()?.length > 0 && (
              <SkillItem iconName="users">
                {polyglot.t("babysitting.child_age_care_from")}&nbsp;
                {getChildsAges()}
              </SkillItem>
            )}
            {getCarePlaces()?.length > 0 && (
              <SkillItem iconName="home">{getCarePlaces()}</SkillItem>
            )}
            {getAdditionnalInfos()?.length > 0 && (
              <SkillItem iconName="info-circle">
                {getAdditionnalInfos()}
              </SkillItem>
            )}
          </List.Group>
        </Block>
      )}
    </div>
  );
};
export default OfferBabySitting;
