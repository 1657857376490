import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  position: relative;
`;

const StyledInput = styled.input`
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  font-size: 0;
`;

const FilePickerEnhancer = ({ shape, children, name, type = 'file', style, ...rest }) => (
  <StyledLabel style={style}>
    {children}
    <StyledInput name={name} type={type} {...rest} />
  </StyledLabel>
);

export default FilePickerEnhancer;
