import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { colors, radius, sizes, spacings } from "../../assets/themes";
import Avatar from "../../components/avatar";
import Block from "../../components/Block";
import { Body14, Body16, Caption } from "../../components/Text";
import linkify from "../../utils/linkify";
import { formatDate } from "../../utils/moment";
import ActionJobberHasCanceled from "./ActionJobberHasCanceled";
import ActionJobberIsUnavailable from "./ActionJobberIsUnavailable";
import ChatDateTimeChange from "./ChatDateTimeChange";

const StyledWidgetWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: ${spacings.m} auto;
`;

const setMargin = ({ first, last }) => {
  if (first) {
    return css`
      margin-top: ${spacings.m};
    `;
  }
  if (last) {
    return css`
      margin-bottom: 0;
    `;
  }
  return null;
};

const StyledWrapper = styled.div`
  width: 100%;
  ${({ own, stacked }) => css`
    display: flex;
    align-items: flex-end;
    flex-direction: ${own ? "row-reverse" : "row"};
    justify-content: ${own ? null : "flex-start"};
    margin-top: 2px;
    margin-bottom: 2px;
    ${setMargin(stacked)};
  `}
  ${({ stacked }) =>
    stacked.middle &&
    css`
      margin-top: 2px;
    `};
  ${({ stacked }) =>
    stacked.last &&
    css`
      margin-top: 2px;
      margin-bottom: ${spacings.m};
    `}
  ${({ stacked }) =>
    stacked.first &&
    css`
      margin-top: ${spacings.m};
      margin-bottom: 2px;
    `}
`;

const StyledMessageContainer = styled.div`
  ${({ own }) => css`
    display: flex;
    align-items: ${own ? "flex-end" : "flex-start"};
    flex-direction: column;
    margin-left: ${!own && spacings.s};
    width: 70%;
  `}
`;

const StyledTimestamp = styled.span`
  ${({ own }) => css`
    margin-top: ${spacings.xs};
    text-align: ${own && "right"};
  `}
`;

const defaultBorderRadius = `calc((${spacings.m} + var(--font-size-body16) + ${spacings.m}) / 2)`;

const setRadius = (stacked, own) => {
  const L = own ? "left" : "right";
  const R = own ? "right" : "left";
  if (stacked.first) {
    return css`
      border-top-${L}-radius: ${defaultBorderRadius};
      border-top-${R}-radius: ${defaultBorderRadius};
      border-bottom-${L}-radius: ${defaultBorderRadius};
    `;
  }
  if (stacked.last) {
    return css`
      border-top-${L}-radius: ${defaultBorderRadius};
      border-bottom-${L}-radius: ${defaultBorderRadius};
      border-bottom-${R}-radius: ${defaultBorderRadius};
    `;
  }
  return css`
      border-top-${L}-radius: ${defaultBorderRadius};
      border-bottom-${L}-radius: ${defaultBorderRadius};
    `;
};

const StyledMessage = styled.div`
  ${({ own, stacked }) => css`
    background: ${own ? colors.primary : colors.backgroundLight};
    color: ${own ? colors.onColor : colors.body};
    a {
      color: ${own ? colors.onColor : colors.body};
      &:hover {
        color: ${own ? colors.onColor : colors.body};
      }
      text-decoration: underline;
    }
    > * {
      word-break: break-word;
      white-space: break-spaces;
    }
    border-radius: ${radius.s};
    padding: ${spacings.s} ${spacings.m};
    ${setRadius(stacked, own)}
  `}
`;

const ChatMessage = ({
  kind,
  user,
  body,
  isLast,
  offerId,
  action,
  customTimestamp,
  forceMainTimestamp,
  date_time_change,
  discussable_id,
  discussable_url,
  id,
  current_user,
  own,
  date,
  hour,
  stacked,
}) => {
  const [showTimestamp, setShowTimestamp] = useState(false);
  return (
    <Block display="flex" flexDirection="column">
      <Block display="flex" alignItems="center" justifyContent="center">
        {kind === "information" && (
          <Block width="100%" margin={spacings.s}>
            <Body14 align="center" color={colors.muted}>
              {body}
            </Body14>
          </Block>
        )}
        {kind === "date_time_change" && (
          <StyledWidgetWrapper>
            <ChatDateTimeChange
              date_time_change={date_time_change}
              body={body}
              user={user}
              discussable_id={discussable_id}
              current_user={current_user}
              id={id}
            />
          </StyledWidgetWrapper>
        )}
      </Block>
      <StyledWrapper own={own} stacked={stacked}>
        {kind === "message" && (
          <Block width="100%">
            <Block
              display="flex"
              justifyContent={own ? "flex-end" : "flex-start"}
            >
              {!user?.is_current_user && (
                <Block width={sizes.size48} position="relative">
                  {(stacked?.last || !stacked) && (
                    <Block position="absolute" bottom="0" right="0">
                      <Avatar
                        src={user?.avatar}
                        size={sizes.size48}
                        name={user?.first_name}
                      />
                    </Block>
                  )}
                </Block>
              )}
              <StyledMessageContainer own={own} stacked={stacked}>
                <div onClick={() => setShowTimestamp((s) => !s)}>
                  <StyledMessage own={own} stacked={stacked}>
                    <Body16>{linkify(body)}</Body16>
                  </StyledMessage>
                </div>
                <Block overflow="hidden">
                  <AnimatePresence initial={false}>
                    {(showTimestamp || isLast) && (
                      <motion.div
                        initial={{ y: -20 }}
                        animate={{ y: 0 }}
                        transition={{ ease: "easeInOut", duration: 0.1 }}
                      >
                        <StyledTimestamp
                          as={Caption}
                          color={colors.muted}
                          own={own}
                          stacked={stacked}
                        >
                          {customTimestamp}
                        </StyledTimestamp>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Block>
              </StyledMessageContainer>
            </Block>
            {action === "jobber_is_unavailable" && (
              <StyledWidgetWrapper>
                <ActionJobberIsUnavailable offerId={offerId} user={user} />
              </StyledWidgetWrapper>
            )}
            {action === "jobber_has_canceled" && (
              <StyledWidgetWrapper>
                <ActionJobberHasCanceled
                  discussable_url={discussable_url}
                  user={user}
                />
              </StyledWidgetWrapper>
            )}
          </Block>
        )}
      </StyledWrapper>
      <Block order="-2">
        <AnimatePresence initial={false}>
          {(showTimestamp || forceMainTimestamp) && (
            <motion.div
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              transition={{ ease: "easeInOut", duration: 0.1 }}
              style={{
                marginTop: stacked ? spacings.l : spacings.m,
                marginBottom: spacings.s,
              }}
            >
              <Body14 color={colors.muted} align="center">
                {formatDate(date, "dddd DD MMMM")} à {hour}
              </Body14>
            </motion.div>
          )}
        </AnimatePresence>
      </Block>
    </Block>
  );
};

const areEqual = (p, n) => p.timestamp === n.timestamp && p.isLast === n.isLast;
export default React.memo(ChatMessage, areEqual);
