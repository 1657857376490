import React, { useState } from "react";
import { spacings } from "../assets/themes";
import { Block, Button, InfiniteScrollEnhancer, Spinner } from "../components";
import { BUTTON } from "../components/Styles/variants";
import { useLoadMoreRatings } from "../modules/routes/jobber-profiles-routes";
import polyglot from "../utils/polyglot";
import JobberProfileRatingFilters from "./jobberProfileRating/JobberProfileRatingFilters";
import UserComment from "./jobberProfileRating/UserComment";

const JobberProfileRating = ({
  category_id,
  category_name,
  rate_count_for_rate_1,
  rate_count_for_rate_2,
  rate_count_for_rate_3,
  rate_count_for_rate_4,
  rate_count_for_rate_5,
  rates_count_for_category,
  user_id,
}) => {
  const [currRating, setRating] = useState(
    category_id && rates_count_for_category ? category_id : null
  );
  const loadMoreRatings = useLoadMoreRatings({
    userId: user_id,
    categoryId: currRating > 5 && currRating !== null && category_id,
    rate: currRating <= 5 && currRating !== null && currRating,
  });

  return (
    <div className="mt-2">
      <JobberProfileRatingFilters
        rate={currRating}
        onChange={(rate) => {
          setRating(rate);
        }}
        category_id={category_id}
        category_name={category_name}
        rate_count_for_rate_1={rate_count_for_rate_1}
        rate_count_for_rate_2={rate_count_for_rate_2}
        rate_count_for_rate_3={rate_count_for_rate_3}
        rate_count_for_rate_4={rate_count_for_rate_4}
        rate_count_for_rate_5={rate_count_for_rate_5}
        rates_count_for_category={rates_count_for_category}
      />
      <InfiniteScrollEnhancer
        query={loadMoreRatings}
        unionBy="rates"
        renderIntersection={({ isFetching, enabled, fetchNextPage }) =>
          enabled && (
            <Block marginTop={spacings.m}>
              <Button.Medium
                kind={BUTTON.KIND.SECONDARY}
                isLoading={isFetching}
                onClick={fetchNextPage}
              >
                {polyglot.t("common.see_more_comments")}
              </Button.Medium>
            </Block>
          )
        }
      >
        {({ isLoading, data }) =>
          !isLoading ? (
            <>
              <div className="position-relative">
                {data?.rates.map((item) => (
                  <UserComment
                    key={`comment-${item.job_url}${item.customer.first_name}`}
                    avatar={item.customer.avatar}
                    firstName={item.customer.first_name}
                    url={item.customer.url}
                    body={item.comment}
                    createdAt={item.created_at}
                    jobTitle={item.job_title}
                    jobUrl={item.job_url}
                    badge={item.badge}
                    rate={item.rate}
                  />
                ))}
              </div>
            </>
          ) : (
            <Block
              marginY={spacings.l}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner.Medium />
            </Block>
          )
        }
      </InfiniteScrollEnhancer>
    </div>
  );
};

export default JobberProfileRating;
