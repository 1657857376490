import React from "react";
import { colors, sizes, spacings } from "../../../../assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Button,
  H4,
  Icon,
  Link,
  List,
  Popover,
  StatusEnhancer,
} from "../../../../components";
import { BUTTON, POPOVER } from "../../../../components/Styles/variants";
import { useBreakpoints } from "../../../../modules/hooks";
import UserPreview from "../../../../pages-admin/UserPreview";
import { JOBBER } from "../../../../pages-admin/UserPreview/config";
import { formatPhone } from "../../../../utils";
import polyglot from "../../../../utils/polyglot";

const PhoneBtnItem = ({ phone, breakpoint = "md" }) => {
  const breakpoints = useBreakpoints();
  return (
    <Button.Small
      kind={BUTTON.KIND.SECONDARY}
      href={`tel:${phone}`}
      block
      shape={breakpoints.get({
        xs: BUTTON.SHAPE.CIRCLE,
        [breakpoint]: BUTTON.SHAPE.PILL,
      })}
      LeftComponent={breakpoints.get({
        xs: null,
        [breakpoint]: () => (
          <Icon.Medium name="phone-solid" color={colors.primary} />
        ),
      })}
    >
      <Block display={{ xs: "none", [breakpoint]: "block" }}>
        {formatPhone(phone)}
      </Block>
      <Block display={{ xs: "block", [breakpoint]: "none" }}>
        <Icon.Medium name="phone-solid" color={colors.primary} />
      </Block>
    </Button.Small>
  );
};

const UserPhones = ({ users }) => (
  <>
    {users.length === 1 ? (
      <PhoneBtnItem phone={users[0].phone} breakpoint="lg" />
    ) : (
      <Popover.Enhancer
        trigger={POPOVER.TRIGGER_TYPE.CLICK}
        position={POPOVER.POSITIONS.BOTTOM}
        content={() => (
          <Popover.Elem.Menu style={{ width: 400, maxWidth: "95vw" }}>
            <H4 style={{ padding: spacings.m, paddingBottom: 0 }}>
              {polyglot.t("conversations.participating_users")}
            </H4>
            {users.map((user, index, arr) => (
              <UserPreview
                id={user.id}
                kind={JOBBER}
                key={`user-phones-item-${user.id}`}
              >
                <List.Item
                  key={`user-${user.id}`}
                  divider={index !== arr.length - 1}
                  style={{
                    paddingLeft: spacings.m,
                    paddingRight: spacings.m,
                  }}
                  RightComponent={() =>
                    user.phone ? <PhoneBtnItem phone={user.phone} /> : null
                  }
                  LeftComponent={() => (
                    <Link href={user.url}>
                      <Avatar
                        src={user.avatar}
                        size={sizes.size48}
                        name={user.first_name}
                      />
                    </Link>
                  )}
                >
                  <StatusEnhancer isPro={user.is_pro}>
                    <Link numberOfLines={1} href={user.url} color={colors.body}>
                      {user.first_name}
                    </Link>
                  </StatusEnhancer>
                  <Body14 numberOfLines={1} color={colors.muted}>
                    {polyglot.t("conversations.last_active", {
                      date: user.last_seen,
                    })}
                  </Body14>
                </List.Item>
              </UserPreview>
            ))}
          </Popover.Elem.Menu>
        )}
      >
        <Button.Small
          disabled={users.length === 0}
          kind={BUTTON.KIND.SECONDARY}
          shape={BUTTON.SHAPE.PILL}
        >
          <Icon.Medium name="phone" color={colors.primary} />
          <Icon.Medium name="angle-down" color={colors.primary} />
        </Button.Small>
      </Popover.Enhancer>
    )}
  </>
);

export default UserPhones;
