import ReactDOM from "react-dom";
import styled from "styled-components";
import { borderWidth, colors, spacings } from "../../../assets/themes";
import {
  Block,
  Body16,
  Carousel,
  Container,
  Icon,
  Info,
} from "../../../components";
import { useBreakpoints } from "../../../modules/hooks";
import polyglot from "../../../utils/polyglot";

const StyledContainer = styled(Block)`
  border-bottom: solid ${borderWidth.m} ${colors.border};
  margin-top: -1px;
  background: ${colors.background};
  > div > div > div {
    width: 100%;
  }
`;

const CarouselItem = ({ iconName, children, tooltip }) => (
  <Block
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100%"
    marginX={spacings.ml}
  >
    <Block marginRight={spacings.s}>
      <Icon.Large name={iconName} color={colors.primary} />
    </Block>
    <Body16>{children}</Body16>
    {tooltip && <Info>{tooltip}</Info>}
  </Block>
);

const WarrantiesCarousel = () => {
  const breakpoint = useBreakpoints();
  const View = (
    <StyledContainer
      paddingX={spacings.ml}
      paddingY={{ xs: spacings.sm, lg: spacings.m }}
    >
      <Container.Large>
        <Block display="flex" alignItems="center" justifyContent="space-around">
          <Carousel
            showDisabledNavigation={breakpoint.get({ xs: true, lg: false })}
            slideToShow={{ xs: 1, lg: polyglot.insurance ? 3 : 2 }}
          >
            <CarouselItem iconName="user-check">
              {breakpoint.get({
                xs: polyglot.t("categories.warranties.verified_short"),
                lg: polyglot.t("categories.warranties.verified"),
              })}
            </CarouselItem>
            <CarouselItem iconName="phone">
              {breakpoint.get({
                xs: polyglot.t("categories.warranties.support_short"),
                lg: polyglot.t("categories.warranties.support"),
              })}
            </CarouselItem>
            {polyglot.insurance && (
              <CarouselItem iconName="umbrella">
                {breakpoint.get({
                  xs: polyglot.t("categories.warranties.insured_by_name_short"),
                  lg: polyglot.t("categories.warranties.insured_by_name", {
                    name: polyglot.insurance,
                  }),
                })}
              </CarouselItem>
            )}
          </Carousel>
        </Block>
      </Container.Large>
    </StyledContainer>
  );
  return ReactDOM.createPortal(
    View,
    document.getElementsByTagName("header")[0]
  );
};
export default WarrantiesCarousel;
