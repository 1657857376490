import React, { useState } from "react";
import Selectable from "../../components/Selectable";

const JobberProfileRatingFilters = ({
  category_id,
  category_name,
  rate_count_for_rate_1,
  rate_count_for_rate_2,
  rate_count_for_rate_3,
  rate_count_for_rate_4,
  rate_count_for_rate_5,
  rates_count_for_category,
  onChange = () => {},
  rate,
}) => {
  const [checkboxChecked, setCheckboxChecked] = useState(rate);
  const rates_count = [
    rate_count_for_rate_1 || 0,
    rate_count_for_rate_2 || 0,
    rate_count_for_rate_3 || 0,
    rate_count_for_rate_4 || 0,
    rate_count_for_rate_5 || 0,
  ];

  return (
    <div className="d-flex align-items-center flex-wrap mb-3">
      {category_id && (
        <div className="mr-3 mt-2">
          <Selectable.Small
            name="profile-filters"
            type="checkbox"
            disabled={rates_count_for_category === 0}
            onChange={() => {
              const cat_id =
                category_id !== checkboxChecked ? category_id : null;
              onChange(cat_id);
              setCheckboxChecked(cat_id);
            }}
            checked={checkboxChecked === category_id}
            title={`${category_name} ${
              rates_count_for_category !== 0
                ? `(${rates_count_for_category})`
                : ""
            }`}
            id={`checkbox-id-${category_id}`}
          />
        </div>
      )}
      {[4, 3, 2, 1, 0].map((e) => (
        <div className="mr-3 mt-2" key={`checkbox-map-${e}`}>
          <Selectable.Small
            icon="icon-star-solid"
            name="profile-filters"
            type="checkbox"
            disabled={rates_count[e] === 0}
            onChange={() => {
              const id = e + 1 !== checkboxChecked ? e + 1 : null;
              onChange(id);
              setCheckboxChecked(id);
            }}
            checked={checkboxChecked === e + 1}
            title={`${e + 1} ${
              rates_count[e] !== 0 ? `(${rates_count[e]})` : ""
            }`}
            id={`checkbox-id-${e}`}
          />
        </div>
      ))}
    </div>
  );
};

export default JobberProfileRatingFilters;
