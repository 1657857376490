import { CATEGORIES, CATEGORIES_PATH } from "../../config/categories";
import { stringToUrl } from "../../utils";

export const getIdData = (id) => CATEGORIES.find((e) => e.id === id);
export const getDataByName = (name) =>
  CATEGORIES.find((e) => stringToUrl(e.n) === stringToUrl(name));
export const getDataById = (id) => CATEGORIES.find((e) => e.id === id);

export const getCategoriesPathById = (id, categories = CATEGORIES_PATH) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const category of categories) {
    if (category.id === id) {
      return [id];
    }
    if (category.children) {
      const subCategoryPath = getCategoriesPathById(id, category.children);
      if (subCategoryPath) {
        return [category.id, ...subCategoryPath];
      }
    }
  }
  return null;
};

export const getParentCategoryById = (id, categories = CATEGORIES_PATH) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const category of categories) {
    if (category.children) {
      // eslint-disable-next-line no-restricted-syntax
      for (const child of category.children) {
        if (child.id === id) {
          return category;
        }
      }
      const parentCategory = getParentCategoryById(id, category.children);
      if (parentCategory) {
        return parentCategory;
      }
    }
  }
  return null;
};

export const getCategoryDataById = (id, categories = CATEGORIES_PATH) => {
  if (!id) return categories;
  // eslint-disable-next-line no-restricted-syntax
  for (const category of categories) {
    if (category.id === id) {
      return category.children;
    }
    if (category.children) {
      const foundSubCategory = getCategoryDataById(id, category.children);
      if (foundSubCategory) {
        return foundSubCategory;
      }
    }
  }
  return null;
};
