import { colors, spacings } from "../../assets/themes";
import Block from "../Block";
import { ButtonBase } from "../Styles/Base";
import { StyledBody, StyledHeaderImage } from "./Styled";

const CardWrapper = ({ onClick, href, children, ...rest }) => {
  if (href)
    return (
      <a
        href={href}
        onClick={onClick}
        style={{
          textDecoration: "none",
          display: "flex",
          flexDirection: "column",
          color: "initial",
          width: "100%",
        }}
        {...rest}
      >
        {children}
      </a>
    );
  if (onClick)
    return (
      <ButtonBase onClick={onClick} {...rest}>
        {children}
      </ButtonBase>
    );
  return <Block {...rest}>{children}</Block>;
};

const Card = ({
  headerImage,
  className,
  children,
  onClick,
  href,
  target,
  withGutters,
}) => (
  <CardWrapper
    className={className}
    target={target}
    href={href}
    onClick={onClick}
    css={`
      overflow: hidden;
      position: relative;
      border-radius: ${spacings.m};
      ${href || onClick
        ? `&:hover {
        ${StyledHeaderImage} {
          &:before {
            transform: scale(1.05);
          }
        }
      }`
        : null}
    `}
  >
    {headerImage && (
      <StyledHeaderImage
        width="100%"
        height={160}
        display="block"
        backgroundColor={colors.gray100}
        position="relative"
        overflow="hidden"
        css={`
          &:before {
            content: "";
            background-image: ${`url(${headerImage})`};
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            transform: scale(1);
            transition: transform 0.2s ease-out;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        `}
      />
    )}
    {children && (
      <StyledBody
        flex="1"
        marginX={withGutters ? spacings.m : null}
        marginTop={withGutters ? spacings.m : null}
        marginBottom={withGutters ? spacings.m : null}
      >
        {children}
      </StyledBody>
    )}
  </CardWrapper>
);
export default Card;
