import { motion } from "framer-motion";
import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { colors, sizes, spacings } from "../../assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Button,
  ConditionnalWrapper,
  Divider,
  H5,
  Icon,
  Link,
  List,
} from "../../components";
import { BUTTON } from "../../components/Styles/variants";
import { JOB, JOBBER } from "../../pages-admin/UserPreview/config";
import UserPreview from "../../pages-admin/UserPreview";
import polyglot from "../../utils/polyglot";
import UserPhones from "./account/conversation-header/UserPhones";
import { INBOXES_PATH } from "./config/routes";

const ConversationHeader = ({ users, title, url, discussable_id }) => {
  const marqueeRef = useRef();
  const marqueeWrapperRef = useRef();
  const history = useHistory();
  const callIsAvailable = !!users.find((u) => u.phone);

  return (
    <div>
      <List.Item
        divider={false}
        style={{
          paddingLeft: spacings.m,
          paddingRight: spacings.m,
          overflow: "hidden",
        }}
        LeftComponent={() => (
          <>
            <Block display={{ xs: "block", md: "none" }}>
              <Button.Medium
                css={`
                  margin-right: ${spacings.s};
                  margin-left: calc(${spacings.s} * -1);
                `}
                onClick={() => {
                  // Simple front-end history.goBack() was causing problem when the redirection come from payment bc the page wasnt refreshed
                  if (history.location.search.includes("from_job=true")) {
                    history.goBack();
                  } else {
                    history.replace(INBOXES_PATH);
                  }
                }}
                shape={BUTTON.SHAPE.CIRCLE}
                kind={BUTTON.KIND.MINIMAL}
              >
                <Icon.Large name="arrow-left" />
              </Button.Medium>
            </Block>
            <Block position="relative">
              {users?.length === 0 && <Avatar size={sizes.size48} />}
              <ConditionnalWrapper
                condition={users.length === 1}
                wrapper={(children) => (
                  <UserPreview id={users[0].id} kind={JOBBER}>
                    <Link href={users[0].url}>{children}</Link>
                  </UserPreview>
                )}
              >
                <Avatar.Group>
                  {users.map((user) => (
                    <Avatar
                      src={user.avatar}
                      key={`user-avatar${user.id}`}
                      size={sizes.size48}
                      name={user.first_name}
                    />
                  ))}
                </Avatar.Group>
              </ConditionnalWrapper>
            </Block>
          </>
        )}
        RightComponent={
          callIsAvailable ? () => <UserPhones users={users} /> : null
        }
      >
        <UserPreview id={discussable_id} kind={JOB}>
          <H5 href={url} as={Link} color={colors.body} numberOfLines={1}>
            {title}
          </H5>
        </UserPreview>
        <Block overflow="hidden" width="100%" ref={marqueeWrapperRef}>
          <motion.div
          // animate={{
          //   x: ["0px", `${getMarqueeGap() * -1 || 0}px`, "0px"],
          // }}
          // transition={{ duration: 5, ease: "linear" }}
          >
            <Body14 color={colors.muted} ref={marqueeRef} numberOfLines={2}>
              {users.length === 0 && polyglot.t("conversations.only_you")}
              {users.length === 1 && (
                <>
                  {users[0]?.first_name} •{" "}
                  {polyglot.t("conversations.last_active", {
                    date: users[0]?.last_seen,
                  })}
                </>
              )}
              {users.length >= 2 && users.map((u) => u.first_name).join(", ")}{" "}
            </Body14>
          </motion.div>
        </Block>
      </List.Item>
      <Divider.Cell />
    </div>
  );
};

export default ConversationHeader;
