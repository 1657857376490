import { colors, sizes, spacings } from "../../assets/themes";
import {
  Avatar,
  Block,
  Body16,
  Button,
  Caption,
  H4,
  Modal,
} from "../../components";
import { BUTTON } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";

const ExtraRatingIntro = ({ onClose, jobber, goNext }) => (
  <div>
    <Modal.Item.Header onClose={onClose} />
    <Modal.Item.Wrapper>
      <Block
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={spacings.xxs}
        height="50vh"
      >
        <Block marginBottom={spacings.m}>
          <Avatar
            src={jobber.avatar}
            isTopJobber={jobber.is_top_jobber}
            size={sizes.size128}
          />
        </Block>
        <H4 align="center">
          {polyglot.t("extra_rating.intro.tell_us_more_about_first_name", {
            first_name: jobber.first_name,
          })}
        </H4>
        <Body16 align="center">
          {polyglot.t("extra_rating.intro.description")}
        </Body16>

        <Caption strong color={colors.muted}>
          {polyglot.t("extra_rating.intro.estimated_duration")}
        </Caption>
      </Block>
    </Modal.Item.Wrapper>
    <Modal.Item.Footer column>
      <Button.Large type="button" block onClick={goNext}>
        {polyglot.t("extra_rating.intro.answer_the_survey")}
      </Button.Large>
      <Button.Large
        type="button"
        block
        kind={BUTTON.KIND.SECONDARY}
        onClick={onClose}
      >
        {polyglot.t("common.no_thank_you")}
      </Button.Large>
    </Modal.Item.Footer>
  </div>
);
export default ExtraRatingIntro;
