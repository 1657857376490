import { spacings } from "../../../assets/themes";
import { Block, Button, List, NoResult } from "../../../components";
import { BUTTON } from "../../../components/Styles/variants";
import { useAskService, useBreakpoints } from "../../../modules/hooks";
import polyglot from "../../../utils/polyglot";
import ConciergeItem from "./ConciergeItem";
import JobsItem from "./JobsItem";
import JobContent from "./item/JobContent";

const ActiveJobs = ({ data }) => {
  const breakpoints = useBreakpoints();
  const askService = useAskService();
  return (
    <>
      {data?.length > 0 ? (
        <Block marginBottom={{ xs: spacings.m, md: spacings.l }}>
          <Block marginBottom={spacings.s}>
            <List.Header>En cours</List.Header>
          </Block>
          {data.map((job) => (
            <JobsItem
              id={job.id}
              key={`job-${job.id}`}
              title={job.title}
              href={job.url}
              artwork={job.cover}
              subtitle={job.datetime}
              private_jobber={job.private_jobber}
              RightComponent={() => <JobContent {...job} />}
            >
              {job.concierge_customer && (
                <Block marginBottom={spacings.m}>
                  <ConciergeItem {...job.concierge_customer} />
                </Block>
              )}
              <Button.Medium href={job.url} block>
                {polyglot.t("jobs.manage_my_job")}
              </Button.Medium>
            </JobsItem>
          ))}
        </Block>
      ) : (
        <Block paddingBottom={spacings.m}>
          <NoResult
            title={polyglot.t("jobs.no_active_job")}
            subtitle={polyglot.t("jobs.no_active_job_description")}
          >
            {breakpoints.get({
              xs: (
                <Button.Small
                  onClick={() => askService.show()}
                  shape={BUTTON.SHAPE.PILL}
                >
                  {polyglot.t("jobs.ask_service")}
                </Button.Small>
              ),
              md: (
                <Button.Medium onClick={() => askService.show()}>
                  {polyglot.t("jobs.ask_service")}
                </Button.Medium>
              ),
            })}
          </NoResult>
        </Block>
      )}
    </>
  );
};

export default ActiveJobs;
