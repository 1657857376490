import React from "react";
import polyglot from "@/utils/polyglot";
import { artwork } from "../../assets/categories";
import { borderWidth, colors, shadows, spacings } from "../../assets/themes";
import { Block, List, Shape } from "../../components";
import { LIST, SHAPE } from "../../components/Styles/variants";
import { useBreakpoints } from "../../modules/hooks";

const MainCategoryItem = React.memo(
  ({ elem, onClick }) => {
    const breakpoints = useBreakpoints();
    return (
      <Block
        marginX={{ xs: `calc(${spacings.m} * -1)`, sm: 0 }}
        marginBottom={{ xs: 0, sm: spacings.m }}
      >
        <List.Item
          chevron
          withGutters
          divider={breakpoints.get({ xs: true, sm: false })}
          size={LIST.SIZE.LARGE}
          shape={breakpoints.get({ xs: undefined, sm: LIST.SHAPE.ROUND })}
          key={elem.id}
          css={breakpoints.get({
            xs: undefined,
            sm: `
            box-shadow: ${shadows.xs};
            border: solid ${borderWidth.s} ${colors.border};
            &:hover {
              img {
                transform: scale(1.1);
              }
            }
          `,
          })}
          LeftComponent={() => (
            <Shape.Large
              shape={SHAPE.SHAPE.ROUND}
              backgroundColor="transparent"
              size={breakpoints.get({
                xs: SHAPE.SIZE.MEDIUM,
                md: SHAPE.SIZE.LARGE,
              })}
              css={`
                overflow: hidden;
                margin: ${spacings.xs} 0;
              `}
            >
              <img
                src={elem.id > 10000 ? artwork[29] : artwork[elem.id]}
                alt={polyglot.t(`categories.${elem.id}.name`)}
                css={`
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  transform: scale(1);
                  transition: 0.1s ease-in-out;
                  object-position: right bottom;
                `}
              />
            </Shape.Large>
          )}
          onClick={onClick}
        >
          <List.Elem.Title
            className={breakpoints.get({
              xs: "",
              sm: "font-size-sm-4 ml-2",
            })}
          >
            {polyglot.t(`categories.${elem.id}.name`)}
          </List.Elem.Title>
        </List.Item>
      </Block>
    );
  },
  () => true
);

export default MainCategoryItem;
