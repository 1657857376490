import { colors, radius } from "../../assets/themes";
import polyglot from "../../utils/polyglot";
import Block from "../Block";
import Icon from "../Icon";
import Tag from "../Tag";
import { Body14 } from "../Text";
import { getRate, getColor } from "./utils";

const Rating = ({ rate = 1, count = null, type = "default" }) => {
  const renderStar = () => {
    const MAX_STARS = 5;
    const roundedNumber = Math.round(rate * 2) / 2;
    return (
      <Block position="relative">
        <Block position="absolute" left="0" right="0">
          {new Array(MAX_STARS).fill("").map((_, i) => (
            <Icon.Small
              name="star-solid"
              color={colors.border}
              key={`rating-star-inactive-${i}`}
            />
          ))}
        </Block>
        <Block position="relative">
          {new Array(MAX_STARS).fill("").map((e, i) => {
            const isActive = i < rate;
            const isHalf = roundedNumber % 1 !== 0;
            const isLastActive = isHalf
              ? i === Math.floor(rate)
              : i === Math.round(rate);
            return (
              <Icon.Small
                key={`rating-star-active-${i}`}
                name={`star${isHalf && isLastActive ? "-half" : ""}-solid`}
                color={isActive ? colors.warning : colors.gray400}
              />
            );
          })}
        </Block>
      </Block>
    );
  };

  return (
    <>
      {type === "default" && (
        <div className="text-nowrap user-star-active font-weight-black d-flex align-items-center">
          <span className="rate-small">{renderStar()}</span>
          {count && (
            <div>
              <Body14 className="font-size-2 ml-1" color={colors.muted}>
                {Number(count) > 0 ? `(${Number(count)})` : <span>&nbsp;</span>}
              </Body14>
            </div>
          )}
        </div>
      )}
      {type === "compact" && (
        <>
          {count === null || count > 0 ? (
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <div
                  css={`
                    margin-top: -0.1rem;
                  `}
                >
                  <Icon.Medium
                    name="star-solid"
                    color={getColor(rate)}
                    css={`
                      margin-right: 0.1rem;
                    `}
                  />
                </div>
                <Body14 strong style={{ marginLeft: 1 }}>
                  {getRate(rate)}
                </Body14>
              </div>
              {count !== null && (
                <Body14 color={colors.muted}>
                  &nbsp;({Number(count)} avis)
                </Body14>
              )}
            </div>
          ) : (
            <Block display="inline-flex">
              <Tag.Small
                css={`
                  color: ${colors.primary};
                  background-color: ${colors.primaryLight};
                  border-radius: ${radius.xs};
                `}
                LeftComponent={() => <Icon.Small name="stars" />}
              >
                {polyglot.t("common.new")}
              </Tag.Small>
            </Block>
          )}
        </>
      )}
    </>
  );
};

export default Rating;
