import React from "react";
import styled, { css } from "styled-components";
import { Link as RLink } from "react-router-dom";
import { colors } from "../assets/themes";
import { ButtonBase } from "./Styles/Base";
import { Body16 } from "./Text";

const StyledLink = styled.a`
  ${({ color }) => css`
    color: ${color};
    text-decoration-color: ${color};
    &:hover {
      color: ${color};
      text-decoration: underline;
    }
  `}
`;

const Link = ({
  children,
  href,
  to,
  as,
  onClick,
  color = colors.primary,
  numberOfLines,
  ...rest
}) => (
  <StyledLink
    as={to ? RLink : as}
    to={to}
    href={href}
    color={color}
    onClick={onClick}
    {...rest}
  >
    <Body16 strong numberOfLines={numberOfLines}>
      {children}
    </Body16>
  </StyledLink>
);

export default Link;
