import { spacings } from "@/assets/themes";
import { Block, Button, Divider, Icon, List } from "@/components";
import { BUTTON } from "@/components/Styles/variants";
import { getCategoryFormUrlById } from "@/utils";
import polyglot from "@/utils/polyglot";
import ConciergeItem from "./ConciergeItem";
import JobsItem from "./JobsItem";
import JobContent from "./item/JobContent";

const ClosedJobs = ({ data }) => (
  <>
    {data?.length > 0 && (
      <Block marginBottom={{ xs: spacings.m, md: spacings.l }}>
        <Divider.Group />
        <Block marginBottom={spacings.s}>
          <List.Header>{polyglot.t("common.closed_f")}</List.Header>
        </Block>
        {data.map((job) => (
          <JobsItem
            id={job.id}
            key={`job-${job.id}`}
            title={job.title}
            href={job.url}
            artwork={job.cover}
            subtitle={job.datetime}
            private_jobber={job.private_jobber}
            RightComponent={() => <JobContent {...job} />}
          >
            {job.concierge_customer && (
              <Block marginBottom={spacings.m}>
                <ConciergeItem {...job.concierge_customer} />
              </Block>
            )}
            <Button.Medium href={job.url} kind={BUTTON.KIND.SECONDARY} block>
              {polyglot.t("jobs.see_my_job")}
            </Button.Medium>
            <Block marginTop={spacings.s}>
              <Button.Medium
                block
                href={getCategoryFormUrlById(job.category, {
                  previous_job_id: job.id,
                })}
                kind={BUTTON.KIND.TERTIARY}
                RightComponent={() => <Icon.Medium name="copy" />}
              >
                {polyglot.t("jobs.ask_again")}
              </Button.Medium>
            </Block>
          </JobsItem>
        ))}
      </Block>
    )}
  </>
);

export default ClosedJobs;
