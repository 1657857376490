import React, { useState } from "react";
import { useSelector } from "react-redux";
import { colors, sizes, spacings } from "../../../assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Body16,
  Button,
  Divider,
  H4,
  Icon,
  Link,
  List,
  Modal,
  Popover,
  StatusEnhancer,
  Tag,
} from "../../../components";
import { BUTTON, POPOVER, TAG } from "../../../components/Styles/variants";
import { usePostReportComment } from "../../../modules/routes/job-routes";
import UserPreview from "../../../pages-admin/UserPreview/UserPreview";
import { CUSTOMER, JOBBER } from "../../../pages-admin/UserPreview/config";
import polyglot from "../../../utils/polyglot";
import useJob from "../../../modules/hooks/useJob";

const ReportModal = ({ isOpen, onClose, onChange }) => (
  <Modal.XSmall isOpen={isOpen} onClose={onClose}>
    <Modal.Item.Header onClose={onClose}>
      <H4>{polyglot.t("job.comment_report")}</H4>
    </Modal.Item.Header>
    <List.Item withGutters onClick={onChange}>
      <List.Elem.Title>
        {polyglot.t("job.comment_report_reason1")}
      </List.Elem.Title>
    </List.Item>
    <List.Item withGutters onClick={onChange}>
      <List.Elem.Title>
        {polyglot.t("job.comment_report_reason2")}
      </List.Elem.Title>
    </List.Item>
  </Modal.XSmall>
);

const ReportComment = React.memo(
  ({ id, commentId }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const postReportComment = usePostReportComment({ id, setModalIsOpen });

    const handleReport = () => {
      postReportComment.mutate({ commentId });
    };

    return (
      <>
        <Popover.Enhancer
          trigger={POPOVER.TRIGGER_TYPE.CLICK}
          position={POPOVER.POSITIONS.BOTTOM_RIGHT}
          content={({ close }) => (
            <Popover.Elem.Menu>
              <List.Item
                divider={false}
                withGutters
                LeftComponent={() => <Icon.Large name="exclamation-circle" />}
                onClick={() => {
                  setModalIsOpen(true);
                  close();
                }}
              >
                <List.Elem.Title>
                  {polyglot.t("job.comment_report")}
                </List.Elem.Title>
              </List.Item>
            </Popover.Elem.Menu>
          )}
        >
          <Button.Small shape={BUTTON.SHAPE.CIRCLE} kind={BUTTON.KIND.MINIMAL}>
            <Block alignSelf="flex-start">
              <Icon.Small name="menu-v" color={colors.muted} />
            </Block>
          </Button.Small>
        </Popover.Enhancer>
        <ReportModal
          isOpen={modalIsOpen}
          onClose={() => {
            setModalIsOpen(false);
          }}
          onChange={() => {
            handleReport();
            setModalIsOpen(false);
          }}
        />
      </>
    );
  },
  () => true
);

const Comments = ({ comments, id }) => {
  const { current_user } = useSelector((state) => state.rails);
  const { data } = useJob();

  return (
    <Block flexDirection="column-reverse" display="flex">
      {comments.map((comment, index, arr) => (
        <div key={`comment-job-${comment.id}`}>
          {index !== arr.length - 1 && <Divider.Cell />}
          <List.Item
            divider={false}
            css={`
              padding-right: ${spacings.xs};
            `}
            withGutters
            RightComponent={
              current_user
                ? () => <ReportComment id={id} commentId={comment.id} />
                : null
            }
            LeftComponent={() => (
              <Block alignSelf="flex-start">
                <UserPreview
                  id={comment.user?.id}
                  kind={data.poster?.id === comment.user.id ? CUSTOMER : JOBBER}
                >
                  <Block as={comment.user.url && Link} href={comment.user.url}>
                    <Avatar
                      src={comment.user.avatar}
                      name={comment.user.first_name}
                      size={sizes.size36}
                      isTopJobber={comment.user.is_top_jobber}
                    />
                  </Block>
                </UserPreview>
              </Block>
            )}
          >
            <UserPreview
              id={comment.user?.id}
              kind={data.poster?.id === comment.user.id ? CUSTOMER : JOBBER}
            >
              <Block
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                width="100%"
              >
                <StatusEnhancer
                  content={
                    comment.user.is_me
                      ? () => (
                          <Tag.Small
                            css={`
                              white-space: nowrap;
                            `}
                            kind={TAG.KIND.PRIMARY}
                          >
                            Vous
                          </Tag.Small>
                        )
                      : null
                  }
                >
                  <List.Elem.Title
                    strong
                    color={colors.body}
                    css={`
                      width: 100%;
                    `}
                  >
                    <div
                      as={comment.user.url && Link}
                      href={comment.user.url}
                      color={colors.body}
                      css="display: inline-block;"
                    >
                      {comment.user.first_name}
                    </div>
                    {!!comment.price && (
                      <span
                        css={`
                          color: ${colors.muted};
                        `}
                      >
                        &nbsp;•&nbsp;{polyglot.toSmartCurrency(comment.price)}
                      </span>
                    )}
                    {!!comment.price_per_hour && !comment.price && (
                      <span
                        css={`
                          color: ${colors.muted};
                        `}
                      >
                        &nbsp;•&nbsp;
                        {polyglot.toSmartCurrency(comment.price_per_hour, {
                          pricePerHour: true,
                        })}
                      </span>
                    )}
                  </List.Elem.Title>
                </StatusEnhancer>
              </Block>
            </UserPreview>
            <Body16
              css={`
                word-break: break-word;
                white-space: break-spaces;
              `}
            >
              {comment.body}
            </Body16>
            <Body14
              color={colors.muted}
              css={`
                white-space: nowrap;
              `}
            >
              {comment.created_at}
            </Body14>
          </List.Item>
        </div>
      ))}
    </Block>
  );
};

export default Comments;
