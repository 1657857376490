import { useSelector } from "react-redux";
import { useLocalStorage } from "../../modules/hooks";
import { isBefore, getToday, isSame } from "@/utils";
import { CATEGORY_DATE_FORMAT } from "./constants";

export function useCategoryFormLocalStorage(id) {
  const name = `category-form`;
  const [localStorageData, setItem] = useLocalStorage(name, {});
  const { isConcierge: _isConcierge, isSav: _isSav } = useSelector(
    (s) => s.rails
  );
  const isConcierge = _isConcierge && !_isSav;
  const isSav = _isConcierge && _isSav;

  return {
    get: () => {
      const data = { ...localStorageData?.[id] };
      if (isConcierge || isSav) {
        // prevent back-end crash when phone step is hidden if phone cache is not valid
        delete data.phone;
      }
      if (
        data?.date &&
        (isBefore(
          data.date,
          getToday(CATEGORY_DATE_FORMAT),
          CATEGORY_DATE_FORMAT
        ) ||
          isSame(
            data.date,
            getToday(CATEGORY_DATE_FORMAT),
            CATEGORY_DATE_FORMAT
          ))
      ) {
        delete data.date;
        delete data.end_date;
      }
      return data;
    },
    set: (values) => {
      // create new array  { ...values } = fix strange bug that format values in formik too when set in cookies
      const formatedValues = { ...values };
      // remove photos bc blob cant be saved in localStorage
      delete formatedValues.photos;
      delete formatedValues.uploads;
      delete formatedValues.user;
      delete formatedValues.alert_setting;
      delete formatedValues.price_per_hour;
      // set cookies from formik values
      setItem({ [id]: formatedValues });
    },
    remove: () => {
      setItem({});
    },
  };
}
